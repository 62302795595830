import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { useVotes } from '../contexts/VoteContext';

const VoteButtons = ({ playerId, userId }) => {
  const { 
    votes, 
    userVotes, 
    isLoading, 
    fetchVotes, 
    fetchUserVote, 
    handleVote 
  } = useVotes();

  useEffect(() => {
    console.log('VoteButtons mounted/updated with:', { playerId, userId });
    
    const initializeVotes = async () => {
      if (!playerId) {
        console.log('No playerId provided');
        return;
      }

      await fetchVotes(playerId);
      
      if (userId) {
        console.log('Fetching user vote for:', { playerId, userId });
        await fetchUserVote(playerId, userId);
      } else {
        console.log('No userId provided, skipping user vote fetch');
      }
    };

    initializeVotes();
  }, [playerId, userId, fetchVotes, fetchUserVote]);

  useEffect(() => {
    console.log('Votes state updated:', votes[playerId]);
  }, [votes, playerId]);

  useEffect(() => {
    console.log('UserVotes state updated:', userVotes[playerId]);
  }, [userVotes, playerId]);

  const playerVotes = votes[playerId] || { upvotes: 0, downvotes: 0 };
  const userVote = userVotes[playerId];
  const loading = isLoading[playerId];

  return (
    <div className="flex items-center space-x-4">
      <button
        onClick={() => handleVote(playerId, userId, 'upvote')}
        disabled={loading || !userId}
        className={`flex items-center space-x-1 px-2 py-1 rounded transition-all duration-200 ${
          userVote === 'upvote'
            ? 'bg-green-500 text-white'
            : userId 
              ? 'bg-gray-200 text-gray-700 hover:bg-green-400'
              : 'bg-gray-200 text-gray-400 cursor-not-allowed'
        }`}
        title={!userId ? 'Login required to vote' : userVote === 'upvote' ? 'Remove upvote' : 'Upvote'}
      >
        <FontAwesomeIcon icon={faThumbsUp} />
        <span>{playerVotes.upvotes}</span>
      </button>
      
      <button
        onClick={() => handleVote(playerId, userId, 'downvote')}
        disabled={loading || !userId}
        className={`flex items-center space-x-1 px-2 py-1 rounded transition-all duration-200 ${
          userVote === 'downvote'
            ? 'bg-red-500 text-white'
            : userId
              ? 'bg-gray-200 text-gray-700 hover:bg-red-400'
              : 'bg-gray-200 text-gray-400 cursor-not-allowed'
        }`}
        title={!userId ? 'Login required to vote' : userVote === 'downvote' ? 'Remove downvote' : 'Downvote'}
      >
        <FontAwesomeIcon icon={faThumbsDown} />
        <span>{playerVotes.downvotes}</span>
      </button>
    </div>
  );
};

export default VoteButtons;