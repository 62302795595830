import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faEquals } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

const ComparisonBar = ({ value1, value2 }) => {
    const total = value1 + value2;
    const percentage1 = (value1 / total) * 100;
    const percentage2 = (value2 / total) * 100;

    return (
        <div className="h-2 bg-gray-600 rounded-full overflow-hidden flex">
            <motion.div 
                initial={{ width: 0 }}
                animate={{ width: `${percentage1}%` }}
                className="h-full bg-blue-500"
                transition={{ duration: 1 }}
            />
            <motion.div 
                initial={{ width: 0 }}
                animate={{ width: `${percentage2}%` }}
                className="h-full bg-red-500"
                transition={{ duration: 1 }}
            />
        </div>
    );
};

const PlayerComparisonCard = ({ player1, player2, performance1, performance2 }) => {
    if (!player1 || !player2) return null;

    const compareValues = (val1, val2) => {
        if (!val1 || !val2) return { icon: faEquals, color: 'text-gray-400', percentage: 0 };
        
        const difference = ((val1 - val2) / val2) * 100;
        if (val1 > val2) return { icon: faArrowUp, color: 'text-green-500', percentage: difference };
        if (val1 < val2) return { icon: faArrowDown, color: 'text-red-500', percentage: -difference };
        return { icon: faEquals, color: 'text-gray-400', percentage: 0 };
    };

    const formatNumber = (num) => {
        if (!num) return '0';
        return num.toLocaleString() || '0';
    };

    const getPlayerName = (player) => {
        return `${player.fn || ''} ${player.ln || ''}`.trim();
    };

    const getCompetitionName = (performance) => {
        return performance?.it?.[0]?.n || 'Bundesliga';
    };

    const metrics = [
        { 
            label: 'Marktwert', 
            value1: player1.mv || 0, 
            value2: player2.mv || 0, 
            format: (v) => `${formatNumber(v)}€`,
            icon: '💰'
        },
        { 
            label: 'Punkteschnitt', 
            value1: player1.ap || 0, 
            value2: player2.ap || 0,
            icon: '📈'
        },
        { 
            label: 'Gesamtpunkte', 
            value1: player1.tp || 0, 
            value2: player2.tp || 0,
            icon: '🎯'
        },
        { 
            label: 'Tore', 
            value1: player1.g || 0, 
            value2: player2.g || 0,
            icon: '⚽'
        },
        { 
            label: 'Vorlagen', 
            value1: player1.a || 0, 
            value2: player2.a || 0,
            icon: '👟'
        },
        { 
            label: 'Spielminuten', 
            value1: player1.sec || 0, 
            value2: player2.sec || 0,
            format: (v) => `${Math.round(v/60)}min`,
            icon: '⏱️'
        },
        { 
            label: 'Punkte/Mio €', 
            value1: player1.mv ? ((player1.tp || 0)/(player1.mv/1000000)) : 0,
            value2: player2.mv ? ((player2.tp || 0)/(player2.mv/1000000)) : 0,
            format: (v) => v.toFixed(2),
            icon: '📊'
        }
    ];

    return (
        <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800 rounded-lg shadow-xl overflow-hidden"
        >
            {/* Blue gradient line at top */}
            <div className="h-1 bg-gradient-to-r from-blue-400 to-blue-600" />

            {/* Player Headers with Images */}
            <div className="p-6">
                <div className="flex justify-between items-start mb-8">
                    <div className="relative w-1/3">
                        <div className="relative">
                            <img 
                                src={`https://kickbase.b-cdn.net/pool/playersbig/${player1.i}.png`}
                                alt={getPlayerName(player1)}
                                className="w-32 h-32 object-cover rounded-lg mx-auto"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${player1.tid}.png`;
                                }}
                            />
                            {/* Competition Tag for Player 1 */}
                            <div 
                                className="absolute -top-2 -right-2 bg-blue-500 text-white px-3 py-1 rounded-full transform rotate-12 font-competition text-sm"
                                style={{ fontFamily: 'Bebas Neue, sans-serif' }}
                            >
                                {getCompetitionName(performance1)}
                            </div>
                        </div>
                        <div className="text-center mt-2">
                            <div className="text-xl font-bold text-white">{getPlayerName(player1)}</div>
                            <div className="text-sm text-gray-400">{player1.tn}</div>
                        </div>
                    </div>

                    <div className="text-3xl font-bold text-white self-center">VS</div>

                    <div className="relative w-1/3">
                        <div className="relative">
                            <img 
                                src={`https://kickbase.b-cdn.net/pool/playersbig/${player2.i}.png`}
                                alt={getPlayerName(player2)}
                                className="w-32 h-32 object-cover rounded-lg mx-auto"
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${player2.tid}.png`;
                                }}
                            />
                            {/* Competition Tag for Player 2 */}
                            <div 
                                className="absolute -top-2 -right-2 bg-blue-500 text-white px-3 py-1 rounded-full transform rotate-12 font-competition text-sm"
                                style={{ fontFamily: 'Bebas Neue, sans-serif' }}
                            >
                                {getCompetitionName(performance2)}
                            </div>
                        </div>
                        <div className="text-center mt-2">
                            <div className="text-xl font-bold text-white">{getPlayerName(player2)}</div>
                            <div className="text-sm text-gray-400">{player2.tn}</div>
                        </div>
                    </div>
                </div>

                {/* Metrics */}
                <div className="space-y-4">
                    {metrics.map((metric, idx) => {
                        const comparison = compareValues(metric.value1, metric.value2);
                        const formatValue = metric.format || formatNumber;
                        
                        return (
                            <motion.div 
                                key={idx}
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: idx * 0.1 }}
                                className="relative"
                            >
                                {/* Metric Label */}
                                <div className="text-center mb-2">
                                    <span className="text-xl mr-2">{metric.icon}</span>
                                    <span className="text-gray-400 font-medium">{metric.label}</span>
                                </div>

                                {/* Comparison Bar */}
                                <div className="bg-gray-700 p-4 rounded-lg">
                                    <div className="flex justify-between items-center mb-2">
                                        <motion.span 
                                            className="text-white font-semibold"
                                            initial={{ scale: 0.9 }}
                                            animate={{ scale: 1 }}
                                            whileHover={{ scale: 1.1 }}
                                        >
                                            {formatValue(metric.value1)}
                                        </motion.span>
                                        <div className="flex flex-col items-center">
                                            <FontAwesomeIcon 
                                                icon={comparison.icon} 
                                                className={`${comparison.color} text-lg`}
                                            />
                                            {comparison.percentage !== 0 && (
                                                <span className={`text-xs ${comparison.color}`}>
                                                    {Math.abs(comparison.percentage).toFixed(1)}%
                                                </span>
                                            )}
                                        </div>
                                        <motion.span 
                                            className="text-white font-semibold"
                                            initial={{ scale: 0.9 }}
                                            animate={{ scale: 1 }}
                                            whileHover={{ scale: 1.1 }}
                                        >
                                            {formatValue(metric.value2)}
                                        </motion.span>
                                    </div>
                                    
                                    {/* New Comparison Bar */}
                                    <div className="relative pt-1">
                                        <ComparisonBar 
                                            value1={metric.value1} 
                                            value2={metric.value2} 
                                        />
                                        <div className="flex justify-between text-xs text-gray-400 mt-1">
                                            <span>{((metric.value1 / (metric.value1 + metric.value2)) * 100).toFixed(1)}%</span>
                                            <span>{((metric.value2 / (metric.value1 + metric.value2)) * 100).toFixed(1)}%</span>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        );
                    })}
                </div>
            </div>
        </motion.div>
    );
};

export default PlayerComparisonCard;