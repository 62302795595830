import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getFirestore } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCSKl6kC3tRFdHJJbOrAohZPVv7bL4bDa8",
  authDomain: "kickbase-analytic.firebaseapp.com",
  projectId: "kickbase-analytic",
  storageBucket: "kickbase-analytic.appspot.com",
  messagingSenderId: "711239456594",
  appId: "1:711239456594:web:44ceca1905dec0c24c8c8b",
  measurementId: "G-RX05XRD78G"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const firestore = getFirestore(app);
const functions = getFunctions(app);

const requestForToken = async () => {
  try {
    if (!('serviceWorker' in navigator)) {
      console.log('Service workers are not supported');
      return null;
    }

    // Register service worker if not already registered
    const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
    console.log('Service worker registered:', registration);

    const currentToken = await getToken(messaging, { 
      vapidKey: 'BFHQmdnxCHyJ0OK5bKIOaaWGPkxNsTH9ZcNLGOb3obgS0njFG-jHXobH9qJFLUsLVfJ6HbfQElIAE6S7rLeloLs',
      serviceWorkerRegistration: registration
    });

    if (currentToken) {
      console.log('Current token for client:', currentToken);
      return currentToken;
    } else {
      console.log('No registration token available. Request permission to generate one.');
      return null;
    }
  } catch (err) {
    console.log('An error occurred while retrieving token:', err);
    return null;
  }
};

const scheduleNotification = httpsCallable(functions, 'scheduleNotification');
const unsubscribeFromPlayerNotifications = httpsCallable(functions, 'unsubscribeFromPlayerNotifications');

// Handle foreground messages
onMessage(messaging, (payload) => {
  console.log('Message received in foreground:', payload);
  // You can handle the message here, e.g., show a toast notification
});

export { 
  messaging, 
  getToken, 
  onMessage,
  firestore, 
  requestForToken, 
  scheduleNotification, 
  unsubscribeFromPlayerNotifications 
};
