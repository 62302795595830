import React, { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { usePlayerStore } from './stores/PlayerStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import ClipLoader from "react-spinners/ClipLoader";
import { removeFavoritePlayer } from './api';

const ScoutingOverview = observer(() => {
    const playerStore = usePlayerStore();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortType, setSortType] = useState('default');

    const fetchFavoritePlayers = async () => {
        setError(null);
        setLoading(true);
        try {
            if (!playerStore.leagueId) {
                throw new Error('League ID is not available');
            }
            await playerStore.fetchFavoritePlayers(playerStore.leagueId);
        } catch (err) {
            console.error('Error fetching favorite players:', err);
            setError(`Failed to fetch favorite players: ${err.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFavoritePlayers();
    }, []); // Empty dependency array ensures this runs every time the component mounts

    const sortedFavoritePlayers = useMemo(() => {
        const activePlayers = playerStore.favoritePlayers.filter(player => player.status !== 128);
        const inactivePlayers = playerStore.favoritePlayers.filter(player => player.status === 128);
        
        let sortedActive = [...activePlayers];
        switch (sortType) {
            case 'marketValue':
                sortedActive.sort((a, b) => b.marketValue - a.marketValue);
                break;
            case 'averagePoints':
                sortedActive.sort((a, b) => b.averagePoints - a.averagePoints);
                break;
            default:
                break;
        }
        
        return [...sortedActive, ...inactivePlayers];
    }, [playerStore.favoritePlayers, sortType]);

    const handleRemoveFavoritePlayer = async (playerId) => {
        try {
            const token = localStorage.getItem('token');
            await removeFavoritePlayer(playerId, token);
            await fetchFavoritePlayers(); // Refresh the list after removal
        } catch (error) {
            console.error('Error removing favorite player:', error);
        }
    };

    const renderPlayerCard = (player) => {
        const teamBadgeUrl = `https://kickbase.b-cdn.net/pool/teams/${player.teamId}.png`;
        const fallbackTeamBadgeUrl = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png`;

        return (
            <div key={player.id} className="bg-gray-800 p-6 rounded-xl shadow-2xl max-w-md transition-transform hover:scale-105 relative">
                <div className="flex justify-between items-start mb-4">
                    <div className="flex items-center">
                        <img 
                            src={teamBadgeUrl}
                            alt={player.teamName} 
                            className="w-8 h-8 mr-2 object-cover rounded-full"
                            onError={(e) => { e.currentTarget.src = fallbackTeamBadgeUrl; }}
                        />
                        <div>
                            <h4 className="text-xl font-bold text-white">{player.firstName} {player.lastName}</h4>
                            <p className="text-sm text-gray-400">{player.teamName}</p>
                        </div>
                    </div>
                    <FontAwesomeIcon 
                        icon={faStar} 
                        className="text-yellow-500 hover:text-gray-400 cursor-pointer"
                        onClick={() => handleRemoveFavoritePlayer(player.id)}
                        title="Remove from favorites"
                    />
                </div>

                <div className="flex items-center space-x-4 mb-4">
                    <div className={`w-24 h-24 rounded-full overflow-hidden border-2 ${player.status === 2 ? 'border-yellow-500' : player.status === 1 ? 'border-red-500' : 'border-indigo-500'}`}>
                        <img
                            src={`https://kickbase.b-cdn.net/pool/playersbig/${player.id}.png`}
                            alt={`${player.firstName} ${player.lastName}`}
                            className="w-full h-full object-cover"
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png`;
                            }}
                        />
                    </div>
                    <div>
                        <span className="text-sm text-gray-400 py-1 px-2 rounded-md bg-gray-700">{player.position === 1 ? 'Torwart' : player.position}</span>
                        <div className="mt-2 space-y-2">
                            <span className="text-sm text-gray-400 py-1 px-2 rounded-md bg-gray-700 inline-block mr-2">Punkteschnitt: <strong>{player.averagePoints}</strong></span>
                            <span className="text-sm text-gray-400 py-1 px-2 rounded-md bg-gray-700 inline-block">Gesamt: <strong>{player.totalPoints}</strong></span>
                        </div>
                    </div>
                </div>

                <button className="w-full text-sm font-bold text-white py-2 px-4 rounded-lg shadow-md bg-indigo-600 hover:bg-indigo-700 transition-colors duration-300 mb-2">
                    {player.marketValue.toLocaleString()} €
                </button>

                <div className="grid grid-cols-2 gap-2 text-xs">
                    <div className="bg-gray-700 p-2 rounded-lg">
                        <span className="font-semibold">Teamvielfalt:</span>
                        <p>{playerStore.getTeamDiversity(player)} Spieler aus {player.teamName}</p>
                    </div>
                    <div className="bg-gray-700 p-2 rounded-lg">
                        <span className="font-semibold">Positionsbesetzung:</span>
                        <p>{playerStore.getPositionOccupancy(player.position)} Spieler auf Position {player.position === 1 ? 'Torwart' : player.position}</p>
                    </div>
                    <div className="bg-gray-700 p-2 rounded-lg col-span-2">
                        <span className="font-semibold">Preis-Leistung:</span>
                        <p>
                            {player.averagePoints && player.marketValue
                                ? `${((player.averagePoints / player.marketValue) * 1000000).toFixed(2)} Punkte/Mio €`
                                : 'N/A'}
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <ClipLoader size={50} color={"#123abc"} loading={loading} />
            </div>
        );
    }

    if (error) {
        return <div className="text-red-500 text-center">{error}</div>;
    }

    return (
        <div className="p-4">
            <div className="bg-gray-800 text-white p-4 rounded shadow-md flex justify-between items-center mb-4">
                <select
                    value={sortType}
                    onChange={e => setSortType(e.target.value)}
                    className="w-auto p-2 border bg-gray-700 text-white focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                    <option value="default">Standard</option>
                    <option value="marketValue">Marktwert</option>
                    <option value="averagePoints">Punkteschnitt</option>
                </select>
               
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {sortedFavoritePlayers.length > 0 ? (
                    sortedFavoritePlayers.map(renderPlayerCard)
                ) : (
                    <div className="col-span-full text-center text-white">
                        No favorite players found.
                    </div>
                )}
            </div>
        </div>
    );
});

export default ScoutingOverview;