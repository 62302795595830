import { apiInstance } from './instance';

export const authService = {
    async login(email, password) {
        try {
            const response = await apiInstance.post('/user/login', {
                ext: true,
                em: email,
                loy: false,
                pass: password,
                rep: {}
            });

            if (response.data?.tkn) {
                localStorage.setItem('kb_token', response.data.tkn);
                
                if (response.data.u) {
                    localStorage.setItem('kb_user', JSON.stringify({
                        email: response.data.u.email,
                        name: response.data.u.name,
                        id: response.data.u.id,
                        notifications: response.data.u.notifications
                    }));
                }

                if (response.data.srvl) {
                    localStorage.setItem('kb_leagues', JSON.stringify(
                        response.data.srvl.map(league => ({
                            id: league.id,
                            name: league.name,
                            creator: league.creator,
                            creatorId: league.creatorId
                        }))
                    ));
                }

                return {
                    token: response.data.tkn,
                    user: response.data.u,
                    leagues: response.data.srvl,
                    expires: response.data.tknex
                };
            }
            
            throw new Error('Invalid login response');
        } catch (error) {
            console.error('Login error details:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
            throw error;
        }
    },

    logout() {
        localStorage.removeItem('kb_token');
        localStorage.removeItem('kb_user');
        localStorage.removeItem('kb_leagues');
    },

    isAuthenticated() {
        return !!localStorage.getItem('kb_token');
    },

    getToken() {
        return localStorage.getItem('kb_token');
    },

    getUserInfo() {
        const userStr = localStorage.getItem('kb_user');
        return userStr ? JSON.parse(userStr) : null;
    },

    getLeagues() {
        const leaguesStr = localStorage.getItem('kb_leagues');
        return leaguesStr ? JSON.parse(leaguesStr) : [];
    }
}; 