import axios from 'axios';

export const apiInstance = axios.create({
    baseURL: 'https://api.kickbase.com/v4',
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add request interceptor to include token
apiInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Optional: Add response interceptor to handle 403 errors
apiInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 403) {
            console.error('Authentication error - token might be invalid or expired');
            // Optionally clear token and redirect to login
            localStorage.removeItem('token');
            // window.location.href = '/login'; // Uncomment if you want to redirect
        }
        return Promise.reject(error);
    }
); 