import { playerStoreInstance } from './PlayerStore';
import {
    faFutbol,
    faHandsHelping,
    faExclamationTriangle,
    faShieldAlt,
    faChartLine,
    faCheck,
    faThumbsDown,
    faMagic,
    faEquals,
    faBandAid,
    faFire,
    faHandPaper,
    faBullseye
} from '@fortawesome/free-solid-svg-icons';


class Player {
    constructor(playerData, currentMatchDay, isMarketPlayer = false) {
        
        if (!playerData) {
            throw new Error('Player data must be provided');
        }

        // Map API response fields to Player properties
        this.id = playerData.id || playerData.i;
        this.firstName = playerData.firstName || playerData.fn;
        this.lastName = playerData.lastName || playerData.ln;
        this.fullName = playerData.fullName || `${this.firstName} ${this.lastName}`;
        this.shirtNumber = playerData.shirtNumber || playerData.shn;
        this.teamId = playerData.teamId || playerData.tid;
        this.teamName = playerData.teamName || playerData.tn;
        
        // Status
        this.status = playerData.status || playerData.st || 0;
        this.statusText = playerData.statusText || playerData.stxt;
        this._position = this.mapPosition(playerData.position || playerData.pos);
        this.lastStatus = playerData.lastStatus || playerData.lst;
        this.currentMatchDay = currentMatchDay;
        
        // Performance
        this.totalPoints = playerData.totalPoints || playerData.tp || 0;
        this.averagePoints = playerData.averagePoints || playerData.ap || 0;
        this.goals = playerData.goals || playerData.g || 0;
        this.assists = playerData.assists || playerData.a || 0;
        this.yellowCards = playerData.yellowCards || playerData.y || 0;
        this.redCards = playerData.redCards || playerData.r || 0;
        this.currentDay = playerData.currentDay || playerData.day;
        
        // Market Data
        this.marketValue = playerData.marketValue || playerData.mv || 0;
        this.marketValueTrend = playerData.marketValueTrend || playerData.mvt || 0;
        this.fourHourTrend = playerData.fourHourTrend || playerData.tfhmvt || 0;
        
        // Market Stats
        if (playerData.marketStats) {
            this.marketValueGainLoss = playerData.marketStats.marketValueGainLoss;
            this.sevenDayTrend = playerData.marketStats.sevenDayTrend;
            this.marketStats = {
                marketValueGainLoss: playerData.marketStats.marketValueGainLoss,
                sevenDayTrend: playerData.marketStats.sevenDayTrend,
                fourHourTrend: playerData.marketStats.fourHourTrend,
                lastOwner: playerData.marketStats.lastOwner,
                lastStatus: playerData.marketStats.lastStatus
            };
        } else {
            this.marketValueGainLoss = playerData.marketValueGainLoss || playerData.mvgl || 0;
            this.sevenDayTrend = playerData.sevenDayTrend || 0;
        }
        
        // Player State
        this.isMarketPlayer = isMarketPlayer;
        this.isOnLoan = playerData.isOnLoan || false;
        this.officialPrice = playerData.officialPrice || playerData.ofc || 0;
        
        // History and Upcoming
        this.performanceHistory = playerData.performanceHistory || [];
        this.seasonPerformance = playerData.seasonPerformance || [];
        this.upcomingMatches = playerData.upcomingMatches || [];

        // Market player specific fields
        if (isMarketPlayer) {
            this.price = playerData.price;
            this.expirySeconds = playerData.expirySeconds;
            this.dateTime = playerData.dateTime;
            this.user = playerData.user;
        }

    }

    // Getter for position
    get position() {
        return this._position;
    }

    mapPosition(pos) {
        const positionMap = {
            0: 'Torwart',
            1: 'Torwart',
            2: 'Abwehr',
            3: 'Mittelfeld',
            4: 'Sturm'
        };
        return positionMap[pos] || 'Unknown';
    }

    calculateFitPercentage() {
        try {
            const pointsPerValue = this.averagePoints / this.marketValue;
            const trendFactor = this.marketValueTrend > 0 ? 1.2 : 0.8;
            const valueIncreaseFactor = this.marketValueGainLoss > 0 ? 1.2 : 0.8;
            
            // Calculate base fit
            let fit = (
                (valueIncreaseFactor * 0.4) + 
                (pointsPerValue * 0.4) + 
                (trendFactor * 0.2)
            ) * 100;

            // Normalize fit between 0 and 100
            fit = Math.min(Math.max(fit, 0), 100);
            
            return Math.round(fit);
        } catch (error) {
            console.error('Error calculating fit percentage:', error);
            return 0;
        }
    }

    get futureValueIncrease() {
        try {
            const dailyIncrease = this.marketValueGainLoss / 7; // Assume weekly gain/loss
            const projectedDays = 30;
            const projectedIncrease = dailyIncrease * projectedDays;
            const percentageIncrease = (projectedIncrease / this.marketValue) * 100;
            return Math.max(percentageIncrease, 0);
        } catch (error) {
            return 0;
        }
    }

    get traits() {
        const traits = [];
        
        if (this.marketValueTrend > 0) traits.push('Rising');
        else if (this.marketValueTrend < 0) traits.push('Falling');
        
        if (this.averagePoints > 100) traits.push('High Performer');
        else if (this.averagePoints < 50) traits.push('Low Performer');
        
        const pointsPerMillion = this.averagePoints / (this.marketValue / 1000000);
        if (pointsPerMillion > 5) traits.push('Good Value');
        else if (pointsPerMillion < 2) traits.push('Expensive');
        
        return traits;
    }

    async fetchHistoricalMarketValues() {
        try {
            // Use the market value trend data we already have
            this.marketValues = [
                {
                    date: new Date(),
                    value: this.marketValue
                },
                {
                    date: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // 7 days ago
                    value: this.marketValue - (this.marketValueGainLoss || 0)
                }
            ];

            this.isHistoricalDataFetched = true;
            return this.marketValues;
        } catch (error) {
            console.error('Error fetching historical market values:', error);
            this.marketValues = [];
            throw new Error('No market values found');
        }
    }
}

export default Player;
