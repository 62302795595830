import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { getPlayerMarketValueHistory, getPlayerPerformance, getPlayerDetails } from './api';
import { usePlayerStore } from './stores/PlayerStore';
import PerformanceTable from './components/PerformanceTable';

const PerformanceContent = ({ player, playerStore }) => {
    const [performanceData, setPerformanceData] = useState(null);
    const [detailedData, setDetailedData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPerformanceData = async () => {
            try {
                setLoading(true);
                setError(null);
                
                const [performance, details] = await Promise.all([
                    getPlayerPerformance(playerStore.leagueId, player.id),
                    getPlayerDetails(playerStore.leagueId, player.id)
                ]);
                
                setPerformanceData(performance);
                setDetailedData(details);
            } catch (error) {
                console.error('Error fetching performance data:', error);
                setError('Fehler beim Laden der Performance-Daten');
            } finally {
                setLoading(false);
            }
        };

        fetchPerformanceData();
    }, [player.id, playerStore.leagueId]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-orange-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-red-400 text-center py-4">{error}</div>
        );
    }

    return (
        <div className="bg-gray-800 rounded-lg border border-gray-700 p-4">
            <PerformanceTable performanceData={performanceData} detailedData={detailedData} />
        </div>
    );
};

const PlayerDetailModal = ({ player, onClose }) => {
    const [historicalData, setHistoricalData] = useState([]);
    const [timeInterval, setTimeInterval] = useState(92);
    const [show, setShow] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('market');
    const [marketStats, setMarketStats] = useState({
        highestValue: 0,
        lowestValue: 0,
        marketValueGainLoss: 0,
        totalRevenue: 0
    });
    const playerStore = usePlayerStore();

    const formatValue = (value) => {
        return new Intl.NumberFormat('de-DE', { 
            style: 'currency', 
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(value);
    };

    const formatDate = (date) => {
        return new Intl.DateTimeFormat('de-DE', { 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit' 
        }).format(date);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setError(null);
                const response = await getPlayerMarketValueHistory(playerStore.leagueId, player.id, timeInterval);
                
                if (response && response.it && response.it.length > 0) {
                    const transformedData = response.it.map(item => ({
                        date: new Date(item.dt * 86400000),
                        value: item.mv
                    })).sort((a, b) => b.date - a.date);

                    setHistoricalData(transformedData);
                    
                    const marketValueGainLoss = player.marketStats?.marketValueGainLoss || 0;
                    const values = transformedData.map(item => item.value);
                    setMarketStats({
                        highestValue: Math.max(...values),
                        lowestValue: Math.min(...values),
                        marketValueGainLoss: marketValueGainLoss,
                        totalRevenue: values[0]
                    });
                } else {
                    setError('Keine Marktwertdaten verfügbar');
                }
            } catch (error) {
                console.error('Error fetching market value history:', error);
                setError('Fehler beim Laden der Marktwertdaten');
            } finally {
                setLoading(false);
            }
        };

        if (player && player.id) {
            fetchData();
        }
    }, [player, playerStore.leagueId, timeInterval]);

    if (!show) return null;

    const renderMarketContent = () => (
        <>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
                <div className="bg-gray-800 p-4 rounded-lg border border-gray-700">
                    <p className="text-gray-400 text-sm">Höchster Marktwert</p>
                    <p className="text-lg font-semibold text-gray-100">
                        {formatValue(marketStats.highestValue)}
                    </p>
                </div>
                <div className="bg-gray-800 p-4 rounded-lg border border-gray-700">
                    <p className="text-gray-400 text-sm">Niedrigster Marktwert</p>
                    <p className="text-lg font-semibold text-gray-100">
                        {formatValue(marketStats.lowestValue)}
                    </p>
                </div>
                <div className="bg-gray-800 p-4 rounded-lg border border-gray-700">
                    <p className="text-gray-400 text-sm">Gewinn/Verlust</p>
                    <p className={`text-lg font-semibold ${marketStats.marketValueGainLoss >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                        {formatValue(marketStats.marketValueGainLoss)}
                    </p>
                </div>
                <div className="bg-gray-800 p-4 rounded-lg border border-gray-700">
                    <p className="text-gray-400 text-sm">Aktueller Marktwert</p>
                    <p className="text-lg font-semibold text-gray-100">
                        {formatValue(marketStats.totalRevenue)}
                    </p>
                </div>
            </div>

            <div className="bg-gray-800 p-4 rounded-lg border border-gray-700 mb-6 text-xs">
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={[...historicalData].reverse()} >
                        <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
                        <XAxis 
                            dataKey="date" 
                            tickFormatter={(date) => formatDate(date)}
                            angle={-45}
                            textAnchor="end"
                            height={60}
                            stroke="#9CA3AF"
                        />
                        <YAxis 
                            tickFormatter={(value) => formatValue(value)}
                            width={80}
                            stroke="#9CA3AF"
                        />
                        <Tooltip 
                            formatter={(value) => formatValue(value)}
                            labelFormatter={(date) => formatDate(date)}
                            contentStyle={{
                                backgroundColor: '#1F2937',
                                border: '1px solid #374151',
                                borderRadius: '4px',
                                color: '#F3F4F6'
                            }}
                        />
                        <Line 
                            type="monotone" 
                            dataKey="value" 
                            stroke="#60A5FA" 
                            strokeWidth={2}
                            dot={{ r: 3, fill: '#60A5FA' }}
                            activeDot={{ r: 5, fill: '#60A5FA' }}
                        />
                    </LineChart>
                </ResponsiveContainer>
            </div>

            <div className="bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
                <table className="min-w-full divide-y divide-gray-700">
                    <thead className="bg-orange-500">
                        <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wide text-center">
                                Datum
                            </th>
                            <th className="px-6 py-3 text-right text-xs font-medium text-white uppercase tracking-wider text-center">
                                Marktwert
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-gray-800 divide-y divide-gray-700">
                        {historicalData.map((item, index) => (
                            <tr key={index} className={index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-900'}>
                                <td className="px-6 py-2 whitespace-nowrap  text-black text-center">
                                    {formatDate(item.date)}
                                </td>
                                <td className="px-6 py-2 whitespace-nowrap text-s text-black text-right text-center">
                                    {formatValue(item.value)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );

    const renderPerformanceContent = () => {
        return <PerformanceContent player={player} playerStore={playerStore} />;
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50">
            <div className="relative top-5 mx-auto p-5 border border-gray-700 w-full max-w-4xl rounded-lg bg-gray-900">
                <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-bold text-gray-100">{player.firstName} {player.lastName}</h3>
                    <button onClick={onClose} className="text-gray-400 hover:text-gray-200">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>

                <div className="flex space-x-4 mb-6 border-b border-gray-700">
                    <button
                        className={`py-2 px-4 font-medium transition-colors duration-150 text-center ${
                            activeTab === 'market'
                                ? 'text-orange-500 border-b-2 border-orange-500'
                                : 'text-gray-400 hover:text-gray-300'
                        }`}
                        onClick={() => setActiveTab('market')}
                    >
                        Marktwert
                    </button>
                    <button
                        className={`py-2 px-4 font-medium transition-colors duration-150 ${
                            activeTab === 'performance'
                                ? 'text-orange-500 border-b-2 border-orange-500'
                                : 'text-gray-400 hover:text-gray-300'
                        }`}
                        onClick={() => setActiveTab('performance')}
                    >
                        Performance
                    </button>
                </div>

                {loading ? (
                    <div className="flex justify-center items-center h-64">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-orange-500"></div>
                    </div>
                ) : error ? (
                    <div className="text-red-400 text-center py-4">{error}</div>
                ) : (
                    activeTab === 'market' ? renderMarketContent() : renderPerformanceContent()
                )}
            </div>
        </div>
    );
};

export default PlayerDetailModal;