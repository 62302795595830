import React, { useState, useEffect } from 'react';
import { usePlayerStore } from '../stores/PlayerStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import PlayerComparisonCard from './PlayerComparisonCard';
import playerMappings from '../data/playerMappings.json';
import { getPlayerPerformance, getPlayerDetails } from '../api';

const PlayerComparisonOverlay = ({ currentPlayer, onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState({ exact: [], similar: [] });
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [playerData1, setPlayerData1] = useState(null);
    const [playerData2, setPlayerData2] = useState(null);
    const [loading, setLoading] = useState(false);
    const [comparisonScore, setComparisonScore] = useState({ player1: 0, player2: 0 });
    const playerStore = usePlayerStore();

    // Updated search function with null checks
    useEffect(() => {
        if (!searchTerm || searchTerm.length < 2) {
            setSearchResults({ exact: [], similar: [] });
            return;
        }

        const term = searchTerm.toLowerCase().trim();
        const results = {
            exact: new Set(),
            similar: new Set()
        };

        // Remove duplicates by using a Map with player ID as key
        const uniquePlayers = new Map();
        if (Array.isArray(playerMappings.players)) {
            playerMappings.players.forEach(player => {
                // Only add if not already in the map and player has required fields
                if (!uniquePlayers.has(player.id) && player.id) {
                    uniquePlayers.set(player.id, {
                        ...player,
                        firstName: player.firstName || '',
                        lastName: player.lastName || '',
                        teamName: player.teamName || ''
                    });
                }
            });
        }

        uniquePlayers.forEach(player => {
            // Skip current player and invalid players
            if (!player || player.id === currentPlayer?.id) return;

            const fullName = `${player.firstName} ${player.lastName}`.toLowerCase();
            const lastName = player.lastName?.toLowerCase() || '';
            const firstName = player.firstName?.toLowerCase() || '';
            const teamName = player.teamName?.toLowerCase() || '';

            // Exact matches
            if (fullName === term || 
                lastName === term || 
                firstName === term || 
                teamName === term) {
                results.exact.add(player);
            }
            // Partial matches
            else if (fullName.includes(term) || 
                     lastName.includes(term) || 
                     firstName.includes(term) || 
                     teamName.includes(term)) {
                results.exact.add(player);
            }
            // Similar matches (for typos and partial words)
            else if (lastName.startsWith(term) || 
                     fullName.startsWith(term) || 
                     term.length >= 3 && (
                         levenshteinDistance(lastName, term) <= 2 ||
                         levenshteinDistance(fullName, term) <= 3
                     )) {
                results.similar.add(player);
            }
        });

        setSearchResults({
            exact: Array.from(results.exact),
            similar: Array.from(results.similar)
        });

        console.log('Search results:', {
            exact: results.exact.size,
            similar: results.similar.size,
            term
        });
    }, [searchTerm, currentPlayer]);

    // Levenshtein distance for fuzzy matching
    const levenshteinDistance = (str1, str2) => {
        const track = Array(str2.length + 1).fill(null).map(() =>
            Array(str1.length + 1).fill(null));
        for (let i = 0; i <= str1.length; i += 1) {
            track[0][i] = i;
        }
        for (let j = 0; j <= str2.length; j += 1) {
            track[j][0] = j;
        }
        for (let j = 1; j <= str2.length; j += 1) {
            for (let i = 1; i <= str1.length; i += 1) {
                const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
                track[j][i] = Math.min(
                    track[j][i - 1] + 1,
                    track[j - 1][i] + 1,
                    track[j - 1][i - 1] + indicator
                );
            }
        }
        return track[str2.length][str1.length];
    };

    // Calculate comparison score when both players' data is available
    useEffect(() => {
        if (playerData1 && playerData2) {
            let score1 = 0;
            let score2 = 0;

            const metrics = [
                { key: 'mv', label: 'Marktwert' },
                { key: 'ap', label: 'Punkteschnitt' },
                { key: 'tp', label: 'Gesamtpunkte' },
                { key: 'g', label: 'Tore' },
                { key: 'a', label: 'Vorlagen' },
                { key: 'sec', label: 'Spielminuten' }
            ];

            metrics.forEach(metric => {
                const val1 = playerData1.details[metric.key] || 0;
                const val2 = playerData2.details[metric.key] || 0;
                
                if (val1 > val2) score1++;
                else if (val2 > val1) score2++;
            });

            setComparisonScore({ player1: score1, player2: score2 });
        }
    }, [playerData1, playerData2]);

    // Fetch detailed data when a player is selected
    const fetchPlayerData = async (playerId) => {
        try {
            setLoading(true);
            const [details, performance] = await Promise.all([
                getPlayerDetails(playerStore.leagueId, playerId),
                getPlayerPerformance(playerStore.leagueId, playerId)
            ]);
            return { details, performance };
        } catch (error) {
            console.error('Error fetching player data:', error);
            return null;
        } finally {
            setLoading(false);
        }
    };

    // Handle player selection
    const handlePlayerSelect = async (player) => {
        setSelectedPlayer(player);
        const data = await fetchPlayerData(player.id);
        if (data) {
            setPlayerData2(data);
        }
    };

    // Fetch current player data on mount
    useEffect(() => {
        const fetchCurrentPlayerData = async () => {
            if (!currentPlayer) return;
            const data = await fetchPlayerData(currentPlayer.id);
            if (data) {
                setPlayerData1(data);
            }
        };
        fetchCurrentPlayerData();
    }, [currentPlayer]);

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-900 rounded-lg shadow-2xl w-full max-w-4xl p-6 relative max-h-[90vh] overflow-y-auto">
                <button onClick={onClose} className="absolute top-4 right-4 text-white hover:text-gray-300">
                    <FontAwesomeIcon icon={faTimes} className="text-2xl" />
                </button>

                <div className="text-white">
                    {!selectedPlayer ? (
                        <>
                            <h2 className="text-2xl font-semibold mb-6">
                                Spieler zum Vergleich auswählen
                            </h2>
                            
                            {/* Search Section */}
                            <div className="mb-6">
                                <div className="relative">
                                    <FontAwesomeIcon icon={faSearch} className="absolute left-3 top-3 text-gray-400" />
                                    <input
                                        type="text"
                                        placeholder="Spieler oder Verein suchen..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="w-full pl-10 pr-4 py-2 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                </div>

                                {/* Search Results */}
                                <div className="mt-4">
                                    {searchResults.exact.length > 0 && (
                                        <div className="mb-4">
                                            <h3 className="text-lg font-semibold mb-2">Exakte Treffer</h3>
                                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                                {searchResults.exact.map(player => (
                                                    <button
                                                        key={`exact-${player.id}`}
                                                        onClick={() => handlePlayerSelect(player)}
                                                        className="bg-gray-800 p-3 rounded-lg hover:bg-gray-700 transition-colors flex items-center space-x-2"
                                                    >
                                                        <div className="text-left">
                                                            <div className="font-semibold">{player.firstName} {player.lastName}</div>
                                                            <div className="text-sm text-gray-400">{player.teamName}</div>
                                                        </div>
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {searchResults.similar.length > 0 && (
                                        <div>
                                            <h3 className="text-lg font-semibold mb-2">Ähnliche Treffer</h3>
                                            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                                {searchResults.similar.map(player => (
                                                    <button
                                                        key={`similar-${player.id}`}
                                                        onClick={() => handlePlayerSelect(player)}
                                                        className="bg-gray-800 p-3 rounded-lg hover:bg-gray-700 transition-colors flex items-center space-x-2"
                                                    >
                                                        <div className="text-left">
                                                            <div className="font-semibold">{player.firstName} {player.lastName}</div>
                                                            <div className="text-sm text-gray-400">{player.teamName}</div>
                                                        </div>
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {searchTerm.length >= 2 && 
                                     searchResults.exact.length === 0 && 
                                     searchResults.similar.length === 0 && (
                                        <div className="text-center text-gray-400 mt-4">
                                            Keine Spieler gefunden
                                        </div>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="flex justify-between items-center mb-6">
                                <button
                                    onClick={() => setSelectedPlayer(null)}
                                    className="text-blue-400 hover:text-blue-300"
                                >
                                    ← Anderen Spieler auswählen
                                </button>
                            </div>
                            {playerData1 && playerData2 && (
                                <PlayerComparisonCard
                                    player1={playerData1.details}
                                    player2={playerData2.details}
                                    performance1={playerData1.performance}
                                    performance2={playerData2.performance}
                                    score={comparisonScore}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PlayerComparisonOverlay;