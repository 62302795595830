import React from 'react';

const PerformanceTable = ({ performanceData, detailedData }) => {
    if (!performanceData?.it || !detailedData) {
        return null;
    }

    // Get the latest season's data (last item in the it array)
    const currentSeason = performanceData.it[performanceData.it.length - 1];

    return (
        <div className="mt-6">
            <h3 className="text-xl font-bold text-white mb-4">Saisonverlauf</h3>
            <div className="overflow-x-auto rounded-lg shadow">
                <table className="min-w-full divide-y divide-gray-600">
                    <thead className="bg-gray-800">
                        <tr>
                            <th className="px-3 py-2 text-white text-sm whitespace-nowrap">ST</th>
                            <th className="px-3 py-2 text-white text-sm whitespace-nowrap">Pkt</th>
                            <th className="px-3 py-2 text-white text-sm whitespace-nowrap">Min</th>
                            <th className="px-3 py-2 text-white text-sm whitespace-nowrap">vs</th>
                            <th className="px-3 py-2 text-white text-sm whitespace-nowrap">Erg</th>
                        </tr>
                    </thead>
                    <tbody className="bg-gray-700 divide-y divide-gray-600">
                        {currentSeason.ph.map((match, index) => {
                            const isHomeTeam = match.t1 === detailedData.tid;
                            const opponentTeamId = isHomeTeam ? match.t2 : match.t1;
                            const result = isHomeTeam 
                                ? `${match.t1g}:${match.t2g}`
                                : `${match.t2g}:${match.t1g}`;

                            // Only show the row if there's a result, even if points are 0
                            if (result === "undefined:undefined") return null;

                            return (
                                <tr key={index} className="hover:bg-gray-600 transition-colors">
                                    <td className="px-3 py-2 text-center text-sm text-black whitespace-nowrap">
                                        {match.day}
                                    </td>
                                    <td className={`px-3 py-2 text-center text-sm font-medium whitespace-nowrap ${
                                        match.p > 100 ? 'text-green-400' : 
                                        match.p > 50 ? 'text-yellow-400' : 
                                        'text-red-400'
                                    }`}>
                                        {match.p ? match.p : "-"}
                                    </td>
                                    <td className="px-3 py-2 text-center text-sm text-black whitespace-nowrap">
                                        {match.mp}
                                    </td>
                                    <td className="px-3 py-2">
                                        <div className="flex items-center justify-center space-x-1">
                                            <img 
                                                src={`https://kickbase.b-cdn.net/pool/teams/${opponentTeamId}.png`}
                                                alt="Team"
                                                className="w-5 h-5 rounded-full"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${opponentTeamId}.png`;
                                                }}
                                            />
                                            <span className="text-sm text-black">
                                                {isHomeTeam ? 'H' : 'A'}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="px-3 py-2 text-center text-sm text-black whitespace-nowrap">
                                        {result !== "undefined:undefined" ? result : "-"}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PerformanceTable;
