import React, { useState, useEffect, useMemo } from 'react';
import { playerStoreInstance } from './stores/PlayerStore';
import LeagueSelector from './LeagueSelector';
import { useObserver } from 'mobx-react-lite';
import { ClipLoader } from 'react-spinners';
import { getLeagueUserInfo, getLeagueRanking } from './api';
import { runInAction } from 'mobx';

const Header = ({ user, onLogout }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showLeagueModal, setShowLeagueModal] = useState(false);
    const [showBudgetModal, setShowBudgetModal] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);

    const formatCurrency = (value) => {
        const numValue = Number(value) || 0;
        return new Intl.NumberFormat('de-DE', { 
            style: 'currency', 
            currency: 'EUR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }).format(numValue);
    };

    useEffect(() => {
        const checkUserInfo = () => {
            if (playerStoreInstance.userInfo) {
                setLoading(false);
            }
        };

        checkUserInfo();
        const intervalId = setInterval(checkUserInfo, 1000);
        return () => clearInterval(intervalId);
    }, []);

    // Debug logging
    useEffect(() => {
        console.log('UserInfo:', playerStoreInstance.userInfo);
    }, [playerStoreInstance.userInfo]);

    // Calculate total players from tpc array
    const totalPlayers = useMemo(() => {
        const tpc = playerStoreInstance.userInfo?.tpc;
        if (!tpc || !Array.isArray(tpc)) return 0;
        return tpc.reduce((total, team) => total + Number(team.npt), 0);
    }, [playerStoreInstance.userInfo?.tpc]);

    // Get max players allowed
    const maxPlayers = playerStoreInstance.userInfo?.mppu || 0;

    // Get values from store using getters
    const budget = playerStoreInstance.currentBudget;
    const teamValue = playerStoreInstance.currentTeamValue;
    const realValue = playerStoreInstance.realValue;

    // Debug logging
    useEffect(() => {
        console.log('Current values:', {
            budget,
            teamValue,
            realValue
        });
    }, [budget, teamValue, realValue]);

    // Investment calculation (33% rule)
    const investmentLeft = useMemo(() => {
        if (budget < 0) {
            return (realValue * 0.33) + budget;
        }
        return (teamValue * 0.33) + budget;
    }, [budget, teamValue, realValue]);

    // Updated modal design matching the screenshot
    const BudgetModal = () => (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 px-4">
            <div className="bg-gray-900 rounded-xl shadow-2xl p-6 w-full max-w-md">
                <h2 className="text-xl font-bold text-white mb-6 text-center">Finanzübersicht</h2>
                
                <div className="grid grid-cols-2 gap-4 mb-6">
                    <div className="bg-gray-800 p-4 rounded-lg">
                        <span className="block text-gray-400 text-sm mb-1">Kontostand</span>
                        <span className="block text-white text-lg font-bold">{formatCurrency(budget)}</span>
                    </div>
                    
                    <div className="bg-gray-800 p-4 rounded-lg">
                        <span className="block text-gray-400 text-sm mb-1">Teamwert</span>
                        <span className="block text-white text-lg font-bold">{formatCurrency(teamValue)}</span>
                    </div>
                    
                    <div className="bg-gray-800 p-4 rounded-lg">
                        <span className="block text-gray-400 text-sm mb-1">Echter Wert</span>
                        <span className="block text-white text-lg font-bold">{formatCurrency(realValue)}</span>
                    </div>
                    
                    <div className="bg-gray-800 p-4 rounded-lg">
                        <span className="block text-gray-400 text-sm mb-1">Übriges Budget</span>
                        <span className="block text-white text-lg font-bold">{formatCurrency(investmentLeft)}</span>
                    </div>
                </div>

                <button 
                    onClick={() => setShowBudgetModal(false)}
                    className="w-full bg-red-500 hover:bg-red-600 text-white py-3 rounded-lg transition-colors duration-200 text-center"
                >
                    Schließen
                </button>
            </div>
        </div>
    );

    // Get league name
    const leagueName = playerStoreInstance.userInfo?.lnm || '';

    const FinancialCards = () => (
        <div className="finance-container space-x-4">
            <div className="finance-card p-4 border rounded">
                <span className="block text-gray-400">Kontostand</span>
                <span className="block text-white text-lg">{formatCurrency(budget)}</span>
            </div>
            <div className="finance-card p-4 border rounded">
                <span className="block text-gray-400">Teamwert</span>
                <span className="block text-white text-lg">{formatCurrency(teamValue)}</span>
            </div>
            <div className="finance-card p-4 border rounded">
                <span className="block text-gray-400">Liga Status</span>
                <span className="block text-white text-lg">{playerStoreInstance.userInfo?.bs || 0}</span>
            </div>
            <div className="finance-card p-4 border rounded">
                <span className="block text-gray-400">Liga</span>
                <span className="block text-white text-lg">{leagueName.slice(0, 3)}...</span>
            </div>
        </div>
    );

    const MobileFinancialCards = () => (
        <div className="finance-container flex space-x-2">
            <div className="finance-card p-2 border rounded">
                <span className="block text-gray-400">Kontostand</span>
                <span className="block text-white text-lg">{formatCurrency(budget)}</span>
            </div>
            <div className="finance-card p-2 border rounded">
                <span className="block text-gray-400">Teamwert</span>
                <span className="block text-white text-lg">{formatCurrency(teamValue)}</span>
            </div>
            <div className="finance-card p-2 border rounded">
                <span className="block text-gray-400">Liga Status</span>
                <span className="block text-white text-lg">{playerStoreInstance.userInfo?.bs || 0}</span>
            </div>
            <div className="finance-card p-2 border rounded">
                <span className="block text-gray-400">Liga</span>
                <span className="block text-white text-lg">{leagueName.slice(0, 5)}...</span>
            </div>
        </div>
    );

    const handleLeagueChange = async (selectedLeagueId) => {
        console.log('League changed to:', selectedLeagueId);
        try {
            setLoading(true);
            // Update the store with the new league
            await playerStoreInstance.initData(selectedLeagueId);
            // Close the league modal if it's open
            setShowLeagueModal(false);
        } catch (error) {
            console.error('Error changing league:', error);
        } finally {
            setLoading(false);
        }
    };

    // Initialize data when component mounts
    useEffect(() => {
        const initializeData = async () => {
            try {
                setLoading(true);
                if (playerStoreInstance.leagueId) {
                    await Promise.all([
                        playerStoreInstance.fetchUserInfo(playerStoreInstance.leagueId),
                        playerStoreInstance.fetchLeagueRanking(playerStoreInstance.leagueId)
                    ]);
                }
            } catch (error) {
                console.error('Error initializing data:', error);
            } finally {
                setLoading(false);
            }
        };

        initializeData();
    }, [playerStoreInstance.leagueId]);

    // Add this to track when userInfo changes
    useEffect(() => {
        console.log('👀 UserInfo updated in Header:', playerStoreInstance.userInfo);
    }, [playerStoreInstance.userInfo]);

    // Remove the updateUserInfo call that was causing the error
    useEffect(() => {
        if (!playerStoreInstance.userInfo && playerStoreInstance.leagueId) {
            console.log('No userInfo, fetching...');
            playerStoreInstance.fetchUserInfo(playerStoreInstance.leagueId);
        }
    }, [playerStoreInstance.leagueId]);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        window.location.reload(); // to refresh the page
    };

    return useObserver(() => (
        <div className="bg-gray-800 text-white py-3  lg:py-2 lg:px-4 flex justify-between items-center">
            <div className="flex items-center space-x-4 md:order-none order-1 mt-2 md:mt-0">
                <img 
                    src={require('./analyticlogo.png')} 
                    className="w-40 h-15 md:block hidden" 
                    alt="Kickly Logo" 
                />
                <img 
                    src={require('./analyticlogo.png')} 
                    className="w-18 h-10 md:hidden block ml-[value]" 
                    alt="Analytic Logo" 
                />

                <div className="md:block hidden">
                    <LeagueSelector 
                        onLeagueChange={handleLeagueChange}
                        selectedLeagueId={playerStoreInstance.leagueId}
                    />
                </div>

                <button 
                    className="md:hidden bg-gray-700 p-1.5 rounded" 
                    onClick={() => setShowLeagueModal(true)}
                >
                    {(playerStoreInstance.leagues.find(l => l.id === playerStoreInstance.leagueId)?.name || 'Liga').slice(0, 3)}...
                </button>

                <button className="md:hidden bg-gray-700 p-1.5 rounded" onClick={() => setShowBudgetModal(true)}>Finanzen</button>
                <button className="md:hidden bg-gray-700 p-1.5 rounded" onClick={() => setShowLogoutModal(true)}>Spenden</button>
            </div>
            {
                user ? (
                    <div className="relative flex items-center justify-center space-x-4 md:flex-row md:block hidden">
                        {
                            loading ? (
                                <ClipLoader color={"#ff4601"} size={30} />
                            ) : (
                                <>
                                    <div className="flex items-center space-x-4">
                                        {/* User Info & Dropdown */}

                                        < div className="hidden md:block" >
                                            <button onClick={() => window.location.href = "https://www.paypal.com/paypalme/kaiDetmers"} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-200 ease-in">
                                                Spenden
                                            </button>
                                        </div >
                                        {/* Financial Cards */}
                                        < div className="finance-container space-x-4" >
                                            <div className="finance-card p-4 border rounded">
                                                <span className="block text-gray-600">Kontostand</span>
                                                <span className="block text-lg">{formatCurrency(budget)}</span>
                                            </div>
                                            <div className="finance-card p-4 border rounded">
                                                <span className="block text-gray-600">Teamwert</span>
                                                <span className="block text-lg">{formatCurrency(teamValue)}</span>
                                            </div>
                                            <div className="finance-card p-4 border rounded">
                                                <span className="block text-gray-600">Spieler</span>
                                                <span className="block text-lg">{totalPlayers} / {maxPlayers}</span>
                                            </div>
                                            <div className="finance-card p-4 border rounded">
                                                <span className="block text-gray-600">Liga</span>
                                                <span className="block text-lg">{leagueName.slice(0, 5)}...</span>
                                            </div>
                                        </div >
                                        <div className="flex items-center space-x-2">
                                            <span className="mr-2">{user.name}</span>
                                            <img src={user.profile} alt="User Profile" className="w-8 h-8 rounded-full cursor-pointer" onClick={() => setDropdownOpen(!dropdownOpen)} />
                                        </div>
                                    </div >

                                    {/* View Details button */}
                                    < button className="md:hidden bg-gray-700 p-2 rounded" onClick={() => setShowBudgetModal(true)}> Details</button >
                                    {dropdownOpen && (
                                        <div className="absolute right-1 ease-in-out w-38 bg-gray-700 text-white shadow-lg rounded z-10">
                                            <button onClick={handleLogout} className="block w-full text-left px-4 py-2 hover:bg-gray-600">Abmelden</button>
                                        </div>
                                    )}
                                </>
                            )}
                    </div >
                ) : (
                    <div></div>
                )}

            {
                showLeagueModal && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-80 flex justify-center items-center z-50 md:hidden">
                        <div className="bg-gray-700 p-4 rounded">
                            <LeagueSelector 
                                onLeagueChange={handleLeagueChange}
                                selectedLeagueId={playerStoreInstance.leagueId}
                            />
                            <button 
                                onClick={() => setShowLeagueModal(false)} 
                                className="mt-4 block mx-auto bg-red-500 p-2 rounded text-white"
                            >
                                Schließen
                            </button>
                        </div>
                    </div>
                )
            }

            {
                showLogoutModal && (
                    <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-80 flex justify-center items-center z-50 md:hidden">
                        <div className="flex flex-col justify-center items-center space-y-4 bg-gray-700 p-4 rounded-lg shadow-lg text-white text-sm">
                            <div className="text-center mb-4">
                                Hallo liebe Community! 😊<br />
                                Ich habe dieses Tool mit viel Liebe und Mühe für euch entwickelt. Es ist kostenlos, aber es fallen laufende Kosten an. Jede Unterstützung hilft mir, meine Kosten zu decken. Vielen Dank für eure Unterstützung!
                            </div>
                            <div className="mb-4">
                                <button onClick={() => window.location.href = "https://www.paypal.com/paypalme/kaiDetmers"} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-200 ease-in">
                                    Spenden
                                </button>
                            </div>

                            <div className="flex justify-center items-center space-x-4 mt-15">
                                <button onClick={onLogout} className="block bg-red-500 hover:bg-red-600 p-2 rounded text-white transition duration-200 ease-in">Ausloggen</button>
                                <button onClick={() => setShowLogoutModal(false)} className="block bg-gray-400 hover:bg-gray-500 p-2 rounded transition duration-200 ease-in">Schließen</button>
                            </div>
                        </div>
                    </div>
                )
            }


            {
                showBudgetModal && <BudgetModal />
            }
        </div >
    ));
};


export default Header;




