import React, { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt, faEuroSign, faLevelUpAlt, faLevelDownAlt } from '@fortawesome/free-solid-svg-icons';
import { getPlayerFeed, getPlayerStats } from './api';
import { usePlayerStore } from './stores/PlayerStore';
import botImage from './bot.png';
import ClipLoader from "react-spinners/ClipLoader";

const formatCurrency = (amount) => {
    if (!amount) return '0';
    return `${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} €`;
};

const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString();
};

const PlayerTimeline = ({ playerId, onClose }) => {
    const [feedData, setFeedData] = useState([]);
    const [historicalData, setHistoricalData] = useState([]);
    const [loading, setLoading] = useState(true);
    const playerStore = usePlayerStore();
    const { leagueId } = playerStore;

    useEffect(() => {
        const fetchFeedAndHistoricalData = async () => {
            try {
                setLoading(true);
                const [feed, stats] = await Promise.all([
                    getPlayerFeed(leagueId, playerId),
                    getPlayerStats(leagueId, playerId)
                ]);
                
                const validItems = feed.items.filter(item => item.type === 15 && item.meta && (item.meta.s || item.meta.b));
                setFeedData(validItems);

                if (stats && stats.marketValues) {
                    setHistoricalData(stats.marketValues.reverse());
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchFeedAndHistoricalData();
    }, [playerId, leagueId]);

    const getPlayerValueOnDate = useMemo(() => {
        const sortedData = [...historicalData].sort((a, b) => new Date(b.d) - new Date(a.d));
        return (date) => {
            const targetDate = new Date(date);
            const valueOnOrBeforeDate = sortedData.find(value => new Date(value.d) <= targetDate);
            return valueOnOrBeforeDate ? valueOnOrBeforeDate.m : null;
        };
    }, [historicalData]);

    const totalProfit = useMemo(() => {
        return feedData.reduce((acc, event) => {
            const sellingPrice = event?.meta?.v;
            const playerValueAtTimeOfSale = getPlayerValueOnDate(event.date);
            return acc + (sellingPrice - playerValueAtTimeOfSale);
        }, 0);
    }, [feedData, getPlayerValueOnDate]);

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <ClipLoader size={50} color={"#123abc"} loading={loading} />
            </div>
        );
    }

    return (
        <div className="p-4 bg-gray-900 rounded-lg shadow-xl overflow-y-auto max-h-screen w-full mx-auto">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-white">Spieler Chain</h2>
            </div>
            <div className="mb-5">
                <p className="text-white mb-1">Gesamter Overpay:</p>
                <span className={`font-bold ${totalProfit >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                    {formatCurrency(totalProfit)}
                </span>
            </div>
            {feedData.map(event => {
                const purchasePrice = event?.meta?.purchasePrice;
                const isSellerBot = event?.meta?.s?.n === 'Markt';
                let sellingPrice = event?.meta?.v;
                let playerValueAtTimeOfSale = getPlayerValueOnDate(event.date);

                if (isSellerBot) {
                    sellingPrice = playerValueAtTimeOfSale;
                }

                const gapValue = sellingPrice - playerValueAtTimeOfSale;

                return (
                    <div key={event.id} className="bg-gray-800 rounded-lg p-4 my-4 shadow-md">
                        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-3">
                            <span className="text-gray-400 mb-2 sm:mb-0">{formatDate(event.date)}</span>
                            <span className="text-blue-400 font-bold">
                                <FontAwesomeIcon icon={faEuroSign} className="mr-1" />
                                {sellingPrice && formatCurrency(sellingPrice)}
                            </span>
                        </div>
                        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-3">
                            <div className="flex items-center mb-2 sm:mb-0">
                                <img src={event?.meta?.s?.f || botImage} alt="Seller" className="w-8 h-8 rounded-full object-cover mr-2" />
                                <span className="text-white">{event?.meta?.s?.n || 'Markt'}</span>
                            </div>
                            <FontAwesomeIcon icon={faExchangeAlt} className="text-blue-500 my-2 sm:mx-3" />
                            <div className="flex items-center">
                                <img src={event?.meta?.b?.f || botImage} alt="Buyer" className="w-8 h-8 rounded-full object-cover mr-2" />
                                <span className="text-white">{event?.meta?.b?.n || 'Markt'}</span>
                            </div>
                        </div>
                        {purchasePrice && (
                            <div className="text-gray-400 text-sm">
                                Profit: {formatCurrency(sellingPrice - purchasePrice)}
                            </div>
                        )}
                        <div className="mt-2 text-gray-400 text-sm">
                            {isSellerBot ? 'Aktueller Marktwert' : 'Wert beim Verkauf'}: {playerValueAtTimeOfSale ? formatCurrency(playerValueAtTimeOfSale) : 'N/A'}
                        </div>
                        <div className="mt-1 text-gray-400 text-sm flex items-center">
                            Overpay:
                            <span className="ml-2">
                                <FontAwesomeIcon icon={gapValue > 0 ? faLevelUpAlt : faLevelDownAlt} className={gapValue > 0 ? "text-green-600" : "text-red-600"} />
                            </span>
                            <span className="ml-2">{formatCurrency(Math.abs(gapValue))}</span>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default PlayerTimeline;
