import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSwipeable } from 'react-swipeable';
import TeamOverview from './TeamOverview';
import MarketOverview from './MarketOverview';
import ScoutingOverview from './ScoutingOverview';
import TopPlayersOverview from './TopPlayersOverview';
import { observer } from 'mobx-react';
import { getLeagueUserInfo } from './api';
import { playerStoreInstance } from '../src/stores/PlayerStore';
import { collectLeagueBonus, getCurrentLeagueGift } from './api';
import { toast } from 'react-toastify'; // Import toast for notifications
import { useNavigate } from 'react-router-dom';
import { runInAction } from 'mobx';
import Header from './Header';

const Dashboard = observer(() => {
    const store = playerStoreInstance;
    const navigate = useNavigate();
    const adContainerRef = useRef(null);
    
    const [view, setView] = useState('Team');
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedLeagueId, setSelectedLeagueId] = useState(null);
    const [giftAvailable, setGiftAvailable] = useState(false);
    const [bonusCollected, setBonusCollected] = useState(false);
    const [giftInfo, setGiftInfo] = useState(null);

    const views = ['Team', 'Markt'];
    const currentIndex = views.indexOf(view);


    const handleCollectBonus = useCallback(async () => {
        if (bonusCollected) return;
        try {
            const bonusResponse = await collectLeagueBonus(selectedLeagueId);
            setBonusCollected(true);
            toast.success(`Bonus abgerufen: ${bonusResponse.amount} €`);
        } catch (error) {
            toast.error('Fehler beim Abrufen des Bonus');
        }
    }, [bonusCollected, selectedLeagueId]);

    const handleLeagueChange = useCallback((leagueId) => {
        setSelectedLeagueId(leagueId);
        store.currentLeagueId = leagueId;
        store.initData(leagueId);
    }, [store]);
    // Removed swipe handlers

    useEffect(() => {
        const initDashboard = async () => {
            if (!store.isAuthenticated) {
                navigate('/login');
                return;
            }

            try {
                setIsLoading(true);
                setError(null);

                const leaguesData = await getLeagueUserInfo();
                const fetchedLeagues = leaguesData.leagues || [];
                
                runInAction(() => {
                    store.leagues = fetchedLeagues;
                });

                if (fetchedLeagues.length > 0) {
                    const storedLeagueId = localStorage.getItem('selectedLeagueId');
                    const validLeagueId = storedLeagueId && fetchedLeagues.some(l => l.id === storedLeagueId)
                        ? storedLeagueId
                        : fetchedLeagues[0].id;

                    if (!store.leagueId || store.leagueId !== validLeagueId) {
                        await store.setLeagueId(validLeagueId);
                        localStorage.setItem('selectedLeagueId', validLeagueId);
                    }

                    await store.initData(validLeagueId);
                }
            } catch (err) {
                console.error('Dashboard initialization failed:', err);
                setError(err.message || 'Failed to load dashboard data');
                
                if (err.response?.status === 401) {
                    store.clearAuth();
                    navigate('/login');
                }
            } finally {
                setIsLoading(false);
            }
        };

        initDashboard();
    }, [store, navigate]);

    useEffect(() => {
        if (!selectedLeagueId) return;

        const checkGiftAvailability = async () => {
            try {
                const currentGift = await getCurrentLeagueGift(selectedLeagueId);
                setGiftInfo(currentGift);
                if (currentGift && currentGift.isAvailable) {
                    setGiftAvailable(true);
                    handleCollectBonus();
                }
            } catch (error) {
                console.error('Error checking gift availability:', error);
            }
        };

        checkGiftAvailability();
    }, [selectedLeagueId, handleCollectBonus]);

    const user = {
        name: playerStoreInstance.user?.name || 'User',
        profile: playerStoreInstance.user?.profile || '/default-avatar.png'
    };

    return (
        <>
            <Header 
                user={user}
                onLogout={() => store.clearAuth()}
            />
            <div className="bg-gray-100 min-h-screen" style={{ backgroundColor: "#15141a" }}>
                {isLoading ? (
                    <div className="min-h-screen bg-gray-900 flex items-center justify-center">
                        <div className="text-white">Loading dashboard...</div>
                    </div>
                ) : error ? (
                    <div className="min-h-screen bg-gray-900 flex items-center justify-center">
                        <div className="text-red-500">
                            {error}
                            <button 
                                onClick={() => window.location.reload()} 
                                className="ml-4 px-4 py-2 bg-blue-500 rounded hover:bg-blue-600"
                            >
                                Retry
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="container mx-auto px-4 py-8 text-white">
                        <div className="relative w-62 mb-5 bg-black bg-opacity-60 rounded overflow-hidden cursor-pointer mx-auto sm:mx-0">
                            {giftAvailable && giftInfo && (
                                <div className="my-4 text-center">
                                    <button
                                        className="bg-custom-orange text-white font-bold py-2 px-4 rounded"
                                        onClick={handleCollectBonus}
                                    >
                                        {giftInfo.amount + " "} Bonus abholen
                                    </button>
                                </div>
                            )}
                            <div className="flex h-full w-full">
                                {views.map((viewName) => (
                                    <div
                                        key={viewName}
                                        className={`flex-1 p-1 flex items-center justify-center text-center overflow-hidden cursor-pointer ${view === viewName ? 'bg-custom-orange text-black' : 'text-white'}`}
                                        onClick={() => setView(viewName)}
                                    >
                                        {viewName.charAt(0).toUpperCase() + viewName.slice(1)}
                                    </div>
                                ))}
                            </div>
                        </div>

                        {view === 'Team' && <TeamOverview />}
                        {view === 'Markt' && <MarketOverview />}
                        {view === 'Scouting' && <ScoutingOverview />}
                        
                        <div className="bg-black bg-opacity-50 rounded p-4 mt-10 mb-6 mx-auto text-center max-w-lg">
                            <p>Diese Seite ist komplett kostenlos und werbefrei. Vielleicht mögt ihr mich mit einer Spende unterstützen, um die Serverkosten zu bezahlen. Der Button ist oben auf der Seite. Danke.</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
});

export default Dashboard;