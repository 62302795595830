import React, { useState, useEffect, useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import PlayerDetailModal from "./PlayerDetailModal";
import PlayerTimeline from "./PlayerTimeline";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
     faArrowUp, faArrowDown,faBinoculars, 
 faEquals,
     faChevronDown, faChevronUp,
     faBell, faBellSlash, 
     faInfoCircle, // Add this icon
} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import ClipLoader from "react-spinners/ClipLoader";
import PlayerSearch from './PlayerSearch';
import { usePlayerStore } from '../src/stores/PlayerStore';
import VoteButtons from './components/VoteButtons';
import { getLeagueQuickStats, getUserInfo, getPlayersOnTransfer } from './api';
import { 
    requestForToken, 
    onMessageListener, 
    messaging, 
    scheduleNotification, 
    subscribeToPlayerNotifications, 
    unsubscribeFromPlayerNotifications 
} from './firebase';
import { toast } from 'react-toastify';  // Make sure to import this at the top of your file
import { onMessage } from 'firebase/messaging';
import { Tooltip } from 'react-tooltip'; // Import Tooltip
import { getMessaging, getToken } from 'firebase/messaging';
import { httpsCallable } from 'firebase/functions';
import { getFunctions } from 'firebase/functions';
import LeagueSelector from './LeagueSelector';
import LoadingSpinner from './components/LoadingSpinner';
import { playerService } from './api/playerService';
import PlayerComparisonCard from './components/PlayerComparisonCard';
import PlayerComparisonOverlay from './components/PlayerComparisonOverlay';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';

const MiniTrendGraph = ({ data, width = 50, height = 20 }) => {
    // Validate data
    if (!data || !Array.isArray(data) || data.length < 2) return null;

    // Filter out invalid entries and ensure numbers
    const validData = data
        .map(d => ({ m: Number(d.m) }))
        .filter(d => !isNaN(d.m));

    if (validData.length < 2) return null;

    const values = validData.map(d => d.m);
    const minValue = Math.min(...values);
    const maxValue = Math.max(...values);
    
    // Prevent division by zero
    if (minValue === maxValue) return null;

    const xStep = width / (validData.length - 1);
    const points = validData
        .map((d, i) => {
            const x = i * xStep;
            const y = height - ((d.m - minValue) / (maxValue - minValue)) * height;
            return `${Math.round(x)},${Math.round(y)}`;
        })
        .join(' ');

    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <polyline
                fill="none"
                stroke="#4CAF50"
                strokeWidth="2"
                points={points}
            />
        </svg>
    );
};

// Helper functions defined outside the component
const calculateMarketValueTrend = (player) => {
    if (!player) return "Unbekannt";
    
    // marketValueTrend: 1 = up, 2 = stable, 3 = down
    switch (player.marketValueTrend) {
        case 1:
            return "Steigend";
        case 2:
            return "Stabil";
        case 3:
            return "Fallend";
        default:
            return "Unbekannt";
    }
};

const calculatePlayerData = (player, playerStore) => {
    if (!player || typeof player !== 'object') {
        console.error('Invalid player data:', player);
        return {
            estimatedSalePrice: '-',
            financialPercentage: 0,
            positionCounts: {},
            maxPlayers: {
                'Torwart': 3,
                'Abwehr': 6,
                'Mittelfeld': 8,
                'Sturm': 4
            },
            teamPlayersCount: 0,
            comparisonResult: null,
            gamesPlayed: 0,
            totalGames: 0,
            overallFitPercentage: 0
        };
    }

    // Calculate position counts from playerStore
    const positionCounts = playerStore.players.reduce((acc, p) => {
        acc[p.position] = (acc[p.position] || 0) + 1;
        return acc;
    }, {});

    const maxPlayers = {
        'Torwart': 3,
        'Abwehr': 6,
        'Mittelfeld': 8,
        'Sturm': 4
    };

    const teamPlayersCount = playerStore.players.filter(p => p.teamId === player.teamId).length;
    const financialPercentage = (player.price / playerStore.budget) * 100;

    return {
        estimatedSalePrice: player.marketValue || '-',
        financialPercentage,
        positionCounts,
        maxPlayers,
        teamPlayersCount,
        comparisonResult: null,
        gamesPlayed: player.gamesPlayed || 0,
        totalGames: player.totalGames || 0,
        overallFitPercentage: calculateOverallFit(player, financialPercentage, positionCounts, maxPlayers, teamPlayersCount)
    };
};

const calculateOverallFit = (player, financialPercentage, positionCounts, maxPlayers, teamPlayersCount) => {
    if (!player) return 0;

    let score = 0;
    const weights = {
        financial: 0.4,
        position: 0.3,
        team: 0.3
    };

    // Financial fit (lower percentage is better)
    const financialFit = Math.max(0, 100 - financialPercentage);
    
    // Position fit
    const currentCount = positionCounts[player.position] || 0;
    const maxCount = maxPlayers[player.position] || 1;
    const positionFit = Math.max(0, 100 * (1 - currentCount / maxCount));

    // Team fit (more players from same team is better, up to a point)
    const teamFit = Math.min(100, teamPlayersCount * 20); // 20% per player, max 100%

    score = (financialFit * weights.financial) +
            (positionFit * weights.position) +
            (teamFit * weights.team);

    return Math.round(score);
};

const formatTimeRemaining = (seconds) => {
    if (!seconds) return 'Unbekannt';

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);

    if (hours > 24) {
        const days = Math.floor(hours / 24);
        return `${days}d ${hours % 24}h`;
    }

    if (hours > 0) {
        return `${hours}h ${minutes}m`;
    }

    return `${minutes}m`;
};

const MarketOverview = observer(() => {
    const playerStore = usePlayerStore();

    const [dataModalOpen, setDataModalOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [sortType, setSortType] = useState('expire');
    const [showAllPlayers, setShowAllPlayers] = useState(false);
    const [players, setPlayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const [showFitInfo, setShowFitInfo] = useState({});
    const [calculatedPlayerData, setCalculatedPlayerData] = useState({});
    const [selectedValue, setSelectedValue] = useState(null);
    const [renderKey, setRenderKey] = useState(0);
    const [showRecommendationDetails, setShowRecommendationDetails] = useState({});
    const [userId, setUserId] = useState(() => {
        try {
            const userString = localStorage.getItem('user');
            if (userString) {
                const userData = JSON.parse(userString);
                console.log('Found user in localStorage:', userData);
                return userData.id;
            }
            return null;
        } catch (error) {
            console.error('Error parsing user from localStorage:', error);
            return null;
        }
    });
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [notificationEnabled, setNotificationEnabled] = useState(() => {
        const savedNotifications = localStorage.getItem('notificationEnabled');
        return savedNotifications ? JSON.parse(savedNotifications) : {};
    });
    const [notificationPermission, setNotificationPermission] = useState('default');
    const [showNotificationDialog, setShowNotificationDialog] = useState(false);
    const [pendingNotificationPlayerId, setPendingNotificationPlayerId] = useState(null);
    const [countdowns, setCountdowns] = useState({});
    const [error, setError] = useState(null);
    const [showComparison, setShowComparison] = useState(false);
    const [selectedPlayerForComparison, setSelectedPlayerForComparison] = useState(null);
    const [notificationTiming, setNotificationTiming] = useState({});
    const [showTimingDialog, setShowTimingDialog] = useState(false);
    const [selectedPlayerId, setSelectedPlayerId] = useState(null);
    const [showAdvancedTimings, setShowAdvancedTimings] = useState(false);

    // Use playerStore.budget instead of local state
    const budget = playerStore.budget;

    Modal.setAppElement('#root');

    const mounted = useRef(true);

    useEffect(() => {
        return () => {
            mounted.current = false;
        };
    }, []);

    useEffect(() => {
        console.log('Current userId from localStorage:', userId);
    }, [userId]);

    const fetchData = async () => {
        if (!playerStore.leagueId) {
            setLoading(false);
            return;
        }

        try {
            setLoading(true);
            setError(null);

            // Get market players with enhanced data
            const marketData = await playerService.getMarketPlayers(playerStore.leagueId);
            
            // Enhance each market player with detailed data
            const enhancedPlayersPromises = marketData.items.map(async marketPlayer => {
                try {
                    const enhancedData = await playerService.getEnhancedPlayerData(
                        playerStore.leagueId,
                        marketPlayer.id
                    );

                    // Combine market data with enhanced data
                    return {
                        ...enhancedData,
                        price: marketPlayer.price,
                        expireInSeconds: marketPlayer.expireInSeconds,
                        seller: marketPlayer.seller,
                        isNew: marketPlayer.isNew,
                        isPosLocked: marketPlayer.isPosLocked,
                        dateTime: marketPlayer.dateTime,
                        // Add any other market-specific fields here
                    };
                } catch (error) {
                    console.error(`Error fetching enhanced data for market player ${marketPlayer.id}:`, error);
                    return null;
                }
            });

            const enhancedPlayers = await Promise.all(enhancedPlayersPromises);
            const validPlayers = enhancedPlayers.filter(Boolean);
            
            console.log('=== Market Overview Debug ===');
            console.log('Valid Players Count:', validPlayers.length);
            console.log('Sample Player Data:', validPlayers[0]);
            console.log('Player Fields:', validPlayers[0] ? Object.keys(validPlayers[0]) : 'No players');
            setPlayers(validPlayers);
            
        } catch (error) {
            console.error('Error fetching market data:', error);
            setError('Failed to load market data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [playerStore.leagueId]);

    useEffect(() => {
        if (playerStore.marketPlayers) {
            setLoading(false);
        }
    }, [playerStore.marketPlayers]);

    // Calculate player data for each player
    useEffect(() => {
        const calculatedData = {};
        for (const player of players) {
            calculatedData[player.id] = calculatePlayerData(player, playerStore);
        }
        setCalculatedPlayerData(calculatedData);
    }, [players, playerStore]);

    // Update sorting logic
    const sortedAndFilteredPlayers = useMemo(() => {
        if (!players?.length) return [];
        
        let filteredPlayers = showAllPlayers 
            ? players 
            : players.filter(player => player && !player.seller);
        
        return filteredPlayers
            .filter(player => player != null)
            .sort((a, b) => {
                switch (sortType) {
                    case 'expire':
                        return a.expireInSeconds - b.expireInSeconds;
                    case 'marketValue':
                        return b.marketValue - a.marketValue;
                    case 'averagePoints':
                        return b.averagePoints - a.averagePoints;
                    default:
                        return a.expireInSeconds - b.expireInSeconds;
                }
            });
    }, [players, sortType, showAllPlayers]);

    useEffect(() => {
        console.log('Players state:', players);
        console.log('Sorted and filtered players:', sortedAndFilteredPlayers);
    }, [players, sortedAndFilteredPlayers]);

    const getCountdown = (expireInSeconds) => {
        const totalSeconds = parseInt(expireInSeconds, 10);
        if (totalSeconds <= 0) return "Expired";

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);

        if (hours > 24) {
            const days = Math.floor(hours / 24);
            return `${days}d ${hours % 24}h`;
        }

        if (hours > 0) {
            return `${hours}h ${minutes}m`;
        }

        return `${minutes}m`;
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdowns(prevCountdowns => {
                const updatedCountdowns = { ...prevCountdowns };
                Object.keys(updatedCountdowns).forEach(playerId => {
                    if (updatedCountdowns[playerId] > 0) {
                        updatedCountdowns[playerId] -= 1;
                    }
                });
                return updatedCountdowns;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const initialCountdowns = {};
        sortedAndFilteredPlayers.forEach(player => {
            initialCountdowns[player.id] = parseInt(player.expire, 10);
        });
        setCountdowns(initialCountdowns);
    }, [sortedAndFilteredPlayers]);

    const toggleFitInfo = (playerId) => {
        setShowFitInfo(prev => ({ ...prev, [playerId]: !prev[playerId] }));
    };

    const getFitColor = (percentage) => {
        if (percentage >= 70) return 'text-green-500';
        if (percentage >= 40) return 'text-yellow-500';
        return 'text-red-500';
    };

    // Function to format currency without cents
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
    };

    const formatLargeNumber = (num) => {
        const absNum = Math.abs(num);
        if (absNum >= 1000000) {
            return (num / 1000000).toFixed(2) + 'M';
        } else if (absNum >= 1000) {
            return (num / 1000).toFixed(0) + 'K';
        }
        return num.toFixed(0);
    };

    const getTrendArrows = (trendValue) => {
        const arrows = [];
        const absValue = Math.abs(trendValue);
        const isPositive = trendValue >= 0;
        const icon = isPositive ? faArrowUp : faArrowDown;
        const color = isPositive ? 'text-green-500' : 'text-red-500';

        for (let i = 0; i < 7; i++) {
            if (absValue > i * 100000) { // Adjust this threshold as needed
                arrows.push(<FontAwesomeIcon key={i} icon={icon} className={`${color} mx-0.5`} />);
            } else {
                arrows.push(<FontAwesomeIcon key={i} icon={faEquals} className="text-gray-400 mx-0.5" />);
            }
        }
        return arrows;
    };

    const canBuyPlayer = (player, playerData) => {
        if (!playerStore.leagueInfo) {
            return { canBuy: true, reason: "" }; // Default to allowing purchase if we don't have league info
        }

        const teamPlayersCount = playerStore.players.filter(p => p.teamId === player.teamId).length;
        const totalPlayersCount = playerStore.players.length;
        const financialPercentage = playerData.financialPercentage;

        if (teamPlayersCount >= playerStore.leagueInfo.mpst) {
            return { canBuy: false, reason: "Maximale Spieler pro Team erreicht" };
        }
        if (totalPlayersCount >= playerStore.leagueInfo.pl) {
            return { canBuy: false, reason: "Maximale Spieleranzahl erreicht" };
        }
        if (financialPercentage > 100) {
            return { canBuy: false, reason: "Nicht genug Budget" };
        }
        return { canBuy: true, reason: "" };
    };

    const toggleRecommendationDetails = (playerId) => {
        setShowRecommendationDetails(prev => ({
            ...prev,
            [playerId]: !prev[playerId]
        }));
    };

    useEffect(() => {
        const checkPermission = async () => {
            const permission = await Notification.requestPermission();
            setNotificationPermission(permission);
        };
        checkPermission();
    }, []);

    const standardTimings = [
        { label: '5 Minuten vor Ende', value: 5, type: 'beforeEnd' },
        { label: '10 Minuten vor Ende', value: 10, type: 'beforeEnd' },
        { label: '15 Minuten vor Ende', value: 15, type: 'beforeEnd' },
        { label: '30 Minuten vor Ende', value: 30, type: 'beforeEnd' },
    ];

    const advancedTimings = [
        { label: 'In 1 Minute benachrichtigen', value: 1, type: 'immediate' },
    ];

    const schedulePlayerNotification = async (player, minutes, type) => {
        try {
            const token = await requestForToken();
            if (!token) {
                throw new Error('No FCM token available');
            }

            const now = Math.floor(Date.now() / 1000);
            let notificationTime;
            let expirationTime = now + player.expireInSeconds;
            
            if (type === 'immediate') {
                notificationTime = now + (minutes * 60);
            } else {
                notificationTime = expirationTime - (minutes * 60);
                
                // Check if the notification would be in the past
                if (notificationTime <= now) {
                    toast.error('Auktion endet zu früh für diese Benachrichtigung');
                    return false;
                }
            }

            console.log('Scheduling notification:', {
                player: `${player.firstName} ${player.lastName}`,
                minutes,
                type,
                notificationTime: new Date(notificationTime * 1000).toLocaleString(),
                currentTime: new Date(now * 1000).toLocaleString(),
                expirationTime: new Date(expirationTime * 1000).toLocaleString(),
            });

            const result = await scheduleNotification({
                playerId: player.id,
                playerName: `${player.firstName} ${player.lastName}`,
                notificationTime: notificationTime,
                expirationTime: expirationTime,
                type: type,
                token: token,
                marketValue: player.marketValue,
                currentBid: player.currentBid || 0,
                notificationMinutes: minutes
            });

            if (result.data?.success) {
                setNotificationTiming(prev => ({
                    ...prev,
                    [player.id]: { minutes, type }
                }));
                
                const message = type === 'immediate' 
                    ? `Benachrichtigung wird in ${minutes} ${minutes === 1 ? 'Minute' : 'Minuten'} gesendet`
                    : `Benachrichtigung wird ${minutes} ${minutes === 1 ? 'Minute' : 'Minuten'} vor Ablauf gesendet`;
                    
                toast.success(message);
                return true;
            } else {
                throw new Error(result.data?.message || 'Unknown error');
            }
        } catch (error) {
            console.error('Error scheduling notification:', error);
            toast.error('Fehler beim Planen der Benachrichtigung');
            return false;
        }
    };

    const handleNotificationRequest = async (playerId) => {
        setSelectedPlayerId(playerId);
        setShowTimingDialog(true);
    };

    const NotificationTimingDialog = () => (
        <Modal
            isOpen={showTimingDialog}
            onRequestClose={() => setShowTimingDialog(false)}
            className="bg-gray-800 rounded-lg p-4 sm:p-6 w-11/12 sm:w-96 mx-auto mt-20 relative"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center px-4"
        >
            <div className="flex flex-col space-y-4">
                <div className="relative bg-gray-900 rounded-xl p-4 border border-gray-700 shadow-lg">
                    <div className="absolute -top-3 left-4 px-2 bg-gray-900">
                        <span className="text-orange-500 text-sm font-medium">Benachrichtigungen</span>
                    </div>
                    
                    <div className="flex flex-col space-y-4 mt-2">
                        <h2 className="text-white text-lg sm:text-xl font-semibold">
                            Wann möchtest du benachrichtigt werden?
                        </h2>
                        
                        <p className="text-gray-300 text-sm">
                            Wähle aus, wie lange vor Ablauf der Auktion du eine Benachrichtigung erhalten möchtest.
                        </p>

                        <div className="grid gap-2">
                            {standardTimings.map(timing => (
                                <button
                                    key={timing.value}
                                    onClick={async () => {
                                        setShowTimingDialog(false);
                                        const player = sortedAndFilteredPlayers.find(p => p.id === selectedPlayerId);
                                        if (player) {
                                            setNotificationLoading(prev => ({ ...prev, [selectedPlayerId]: true }));
                                            try {
                                                const success = await schedulePlayerNotification(player, timing.value, timing.type);
                                                if (success) {
                                                    setNotificationEnabled(prev => ({
                                                        ...prev,
                                                        [selectedPlayerId]: true
                                                    }));
                                                }
                                            } finally {
                                                setNotificationLoading(prev => ({ ...prev, [selectedPlayerId]: false }));
                                            }
                                        }
                                    }}
                                    className="flex items-center justify-between bg-gray-800 hover:bg-gray-700 text-white rounded-lg p-4 transition-colors shadow-md border border-gray-700 hover:border-orange-500/50"
                                >
                                    <span className="text-left font-medium">{timing.label}</span>
                                    <svg 
                                        className="w-5 h-5 text-orange-500" 
                                        fill="none" 
                                        stroke="currentColor" 
                                        viewBox="0 0 24 24"
                                    >
                                        <path 
                                            strokeLinecap="round" 
                                            strokeLinejoin="round" 
                                            strokeWidth={2} 
                                            d="M9 5l7 7-7 7"
                                        />
                                    </svg>
                                </button>
                            ))}

                            <button
                                onClick={() => setShowAdvancedTimings(!showAdvancedTimings)}
                                className="flex items-center justify-between bg-gray-800 hover:bg-gray-700 text-white rounded-lg p-4 transition-colors shadow-md border border-gray-700 hover:border-orange-500/50"
                            >
                                <span className="text-left font-medium">Andere Optionen</span>
                                <svg 
                                    className={`w-5 h-5 text-orange-500 transform transition-transform ${showAdvancedTimings ? 'rotate-180' : ''}`}
                                    fill="none" 
                                    stroke="currentColor" 
                                    viewBox="0 0 24 24"
                                >
                                    <path 
                                        strokeLinecap="round" 
                                        strokeLinejoin="round" 
                                        strokeWidth={2} 
                                        d="M19 9l-7 7-7-7"
                                    />
                                </svg>
                            </button>

                            {showAdvancedTimings && (
                                <div className="pl-4 border-l-2 border-orange-500/30">
                                    {advancedTimings.map(timing => (
                                        <button
                                            key={timing.value}
                                            onClick={async () => {
                                                setShowTimingDialog(false);
                                                const player = sortedAndFilteredPlayers.find(p => p.id === selectedPlayerId);
                                                if (player) {
                                                    setNotificationLoading(prev => ({ ...prev, [selectedPlayerId]: true }));
                                                    try {
                                                        const success = await schedulePlayerNotification(player, timing.value, timing.type);
                                                        if (success) {
                                                            setNotificationEnabled(prev => ({
                                                                ...prev,
                                                                [selectedPlayerId]: true
                                                            }));
                                                        }
                                                    } finally {
                                                        setNotificationLoading(prev => ({ ...prev, [selectedPlayerId]: false }));
                                                    }
                                                }
                                            }}
                                            className="flex items-center justify-between w-full bg-gray-800 hover:bg-gray-700 text-white rounded-lg p-4 mt-2 transition-colors shadow-md border border-gray-700 hover:border-orange-500/50"
                                        >
                                            <span className="text-left font-medium">{timing.label}</span>
                                            <svg 
                                                className="w-5 h-5 text-orange-500" 
                                                fill="none" 
                                                stroke="currentColor" 
                                                viewBox="0 0 24 24"
                                            >
                                                <path 
                                                    strokeLinecap="round" 
                                                    strokeLinejoin="round" 
                                                    strokeWidth={2} 
                                                    d="M9 5l7 7-7 7"
                                                />
                                            </svg>
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>

                    <button
                        onClick={() => setShowTimingDialog(false)}
                        className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors"
                        aria-label="Schließen"
                    >
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
            </div>
        </Modal>
    );

    const requestNotificationPermission = async () => {
        const result = await Notification.requestPermission();
        setNotificationPermission(result);
        return result;
    };

    const [notificationLoading, setNotificationLoading] = useState({});

    const handleNotificationDialogConfirm = async () => {
        setShowNotificationDialog(false);
        const permission = await requestNotificationPermission();
        if (permission === 'granted' && pendingNotificationPlayerId) {
            toggleNotification(pendingNotificationPlayerId);
        }
        setPendingNotificationPlayerId(null);
    };

    const toggleNotification = async (playerId) => {
        const player = sortedAndFilteredPlayers.find(p => p.id === playerId);
        if (!player) {
            console.error('Player not found:', playerId);
            return false;
        }

        try {
            if (!notificationEnabled[playerId]) {
                // Subscribe to notifications
                const token = await requestForToken();
                if (!token) {
                    toast.error('Benachrichtigungen sind nicht aktiviert');
                    return false;
                }

                console.log('Subscribing to notifications with:', {
                  playerId: player.id,
                  token: token
                });

                const functions = getFunctions();
                const subscribeFunction = httpsCallable(functions, 'subscribeToPlayerNotifications');
                
                const result = await subscribeFunction({
                  playerId: player.id,
                  token: token
                });

                console.log('Subscription result:', result);

                if (result.data && result.data.success) {
                  // Schedule the notification immediately after successful subscription
                  const notificationScheduled = await schedulePlayerNotification(player, notificationTiming[playerId].minutes, notificationTiming[playerId].type);
                  if (!notificationScheduled) {
                    console.error('Failed to schedule notification');
                    throw new Error('Failed to schedule notification');
                  }
                  return true;
                }
                
                throw new Error(result.data?.message || 'Subscription failed');
            } else {
                // Unsubscribe from notifications
                const token = await requestForToken();
                if (token) {
                    await unsubscribeFromPlayerNotifications({ playerId, token });
                    setNotificationEnabled(prev => {
                        const newState = { ...prev, [playerId]: false };
                        localStorage.setItem('notificationEnabled', JSON.stringify(newState));
                        return newState;
                    });
                    toast.success('Benachrichtigung deaktiviert');
                    return true;
                }
            }
            return false;
        } catch (error) {
            console.error('Error toggling notification:', error);
            toast.error('Fehler beim Aktivieren/Deaktivieren der Benachrichtigung');
            return false;
        }
    };

    const revertNotificationState = (playerId) => {
        setNotificationEnabled(prev => {
            const newState = { ...prev, [playerId]: !prev[playerId] };
            localStorage.setItem('notificationEnabled', JSON.stringify(newState));
            return newState;
        });
    };

    useEffect(() => {
        const unsubscribe = onMessage(messaging, (payload) => {
            console.log('Received foreground message:', payload);
            // Show a toast notification
            toast.info(`${payload.notification.title}: ${payload.notification.body}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        });

        return () => {
            unsubscribe();
        };
    }, []);

    useEffect(() => {
        localStorage.setItem('notificationEnabled', JSON.stringify(notificationEnabled));
    }, [notificationEnabled]);

    useEffect(() => {
        const setupNotifications = async () => {
            try {
                const permission = await Notification.requestPermission();
                setNotificationPermission(permission);
                if (permission === 'granted') {
                    const token = await requestForToken();
                    if (token) {
                        console.log('FCM Token:', token);
                    } else {
                        console.error('Failed to get FCM token');
                    }
                }
            } catch (error) {
                console.error('Error setting up notifications:', error);
            }
        };
        setupNotifications();
    }, []);

    const subscribeToPlayerNotifications = async (player) => {
      if (!player || !player.id) {
        console.error('Invalid player object:', player);
        return false;
      }

      try {
        const token = await requestForToken();
        if (!token) {
          console.error('No FCM token available');
          throw new Error('No FCM token available');
        }

        console.log('Subscribing to notifications with:', {
          playerId: player.id,
          token: token
        });

        const functions = getFunctions();
        const subscribeFunction = httpsCallable(functions, 'subscribeToPlayerNotifications');
        
        const result = await subscribeFunction({
          playerId: player.id,
          token: token
        });

        console.log('Subscription result:', result);

        if (result.data && result.data.success) {
          // Schedule the notification immediately after successful subscription
          const notificationScheduled = await schedulePlayerNotification(player, notificationTiming[player.id].minutes, notificationTiming[player.id].type);
          if (!notificationScheduled) {
            console.error('Failed to schedule notification');
            throw new Error('Failed to schedule notification');
          }
          return true;
        }
        
        throw new Error(result.data?.message || 'Subscription failed');
      } catch (error) {
        console.error('Error subscribing to notifications:', error);
        // Throw the error to be handled by the caller
        throw error;
      }
    };

    // Add this helper function to preload images
    const preloadImage = (src) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(src);
            img.onerror = () => reject();
            img.src = src;
        });
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found');
                    return;
                }

                const userInfo = await getUserInfo(token, playerStore.leagueId);
                if (userInfo && userInfo.id) {
                    setUserId(userInfo.id);
                }
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        };

        fetchUserInfo();
    }, [playerStore.leagueId]);

    // Add loading skeletons
    const PlayerCardSkeleton = () => (
        <div className="bg-gray-800 p-6 rounded-xl shadow-2xl max-w-md animate-pulse">
            <div className="h-4 bg-gray-700 rounded w-3/4 mb-4"></div>
            <div className="h-32 bg-gray-700 rounded mb-4"></div>
            <div className="space-y-3">
                <div className="h-4 bg-gray-700 rounded"></div>
                <div className="h-4 bg-gray-700 rounded w-5/6"></div>
            </div>
        </div>
    );

    const handleCompareClick = (player) => {
        setSelectedPlayerForComparison(player);
        setShowComparison(true);
    };

    return (
        <div key={renderKey} className="p-2 sm:p-4">
            <NotificationTimingDialog />
            {/* Filter and Button Section */}
            <div className="bg-gray-800 text-white p-4 rounded shadow-md flex flex-col md:flex-row justify-between items-center mb-4 w-full">
                <div className="w-full md:w-auto mb-4 md:mb-0">
                    <select
                        value={sortType}
                        onChange={e => setSortType(e.target.value)}
                        className="w-full md:w-auto p-2 border bg-gray-700 text-white focus:outline-none focus:ring-1 focus:ring-indigo-500 rounded"
                    >
                        <option value="expire">Zeit bis Ablauf</option>
                        <option value="marketValue">Marktwert</option>
                        <option value="averagePoints">Durchschnittspunkte</option>
                        <option value="pointsPerEuro">Durchsch. Punkte/Euro</option>
                        <option value="valueChange">MW-Veränderung</option>
                        <option value="valueForMoney">Gesamtpunkte/Euro</option>
                    </select>
                </div>

                <div className="flex items-center">
                    <label htmlFor="showAllPlayers" className="flex items-center cursor-pointer mr-4">
                        <div className="relative">
                            <input
                                id="showAllPlayers"
                                type="checkbox"
                                className="hidden"
                                checked={showAllPlayers}
                                onChange={() => setShowAllPlayers(prev => !prev)}
                            />
                            <div className="toggle__line w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
                            <div className="toggle__dot absolute w-6 h-6 bg-white rounded-full shadow inset-y-0 left-0"></div>
                        </div>
                        <div className="ml-3 text-white">Alle</div>
                    </label>

                 
                </div>
            </div>

            {loading ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                    {[...Array(10)].map((_, i) => <PlayerCardSkeleton key={i} />)}
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4">
                    {sortedAndFilteredPlayers.map(player => {
                     

                        const playerData = calculatedPlayerData[player.id] || {};
                        const trend = calculateMarketValueTrend(player);
                        const { canBuy, reason } = canBuyPlayer(player, playerData);
                        const values = player.performanceHistory || [];

                        return (
                            <div key={player.id} className="bg-gray-800 p-6 rounded-xl shadow-2xl max-w-md transition-transform relative mr-2">
                                {/* Status Badge - New! */}
                                {player.isNew && (
                                    <div className="absolute top-2 left-2 bg-green-500 text-white px-2 py-1 rounded-full text-xs">
                                        Neu
                                    </div>
                                )}
                                
                                
                            

                                {/* Notification button */}
                                <button 
                                    onClick={() => handleNotificationRequest(player.id)}
                                    className="absolute top-4 right-4 transition-colors duration-200"
                                    aria-label={notificationEnabled[player.id] ? "Benachrichtigungen deaktivieren" : "Benachrichtigungen aktivieren"}
                                    tabIndex={0}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' || e.key === ' ') {
                                            handleNotificationRequest(player.id);
                                        }
                                    }}
                                >
                                    {notificationLoading[player.id] ? (
                                        <div className="animate-spin h-5 w-5 border-2 border-white rounded-full border-t-transparent"/>
                                    ) : (
                                        <FontAwesomeIcon 
                                            icon={notificationEnabled[player.id] ? faBell : faBellSlash} 
                                            className={`text-1xl ${
                                                notificationEnabled[player.id] 
                                                    ? 'text-[#f23d00]' 
                                                    : 'text-white hover:text-[#f23d00]'
                                            }`}
                                        />
                                    )}
                                </button>

                                {/* Player basic info */}
                                <div className="flex items-center mb-4">
                                    <img 
                                        src={`https://kickbase.b-cdn.net/pool/playersbig/${player.id}.png`}
                                        alt={`${player.firstName} ${player.lastName}`}
                                        className="w-16 h-16 rounded-full mr-4 object-cover object-center"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = 'https://kickbase.b-cdn.net/images/avatar.png';
                                        }}
                                    />
                                    <div>
                                        <h4 className="text-lg font-bold text-white">{player.firstName} {player.lastName}</h4>
                                        <div className="flex items-center mt-1">
                                            <img 
                                                src={`https://kickbase.b-cdn.net/pool/teams/${player.teamId}.png`}
                                                alt={player.teamName}
                                                className="w-6 h-6 mr-2 rounded-full"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${player.teamId}.png`;
                                                }}
                                            />
                                            <span className="text-xs text-gray-400">{player.teamName}</span>
                                        </div>
                                        <div className="flex items-center mt-1">
                                            <span className="text-xs text-gray-400">
                                                {player.position === 1 ? 'Torwart' : 
                                                 player.position === 2 ? 'Abwehr' : 
                                                 player.position === 3 ? 'Mittelfeld' : 'Sturm'}
                                            </span>
                                            <span className="text-xs text-gray-400 ml-2">#{player.shirtNumber}</span>
                                        </div>
                                    </div>
                                </div>

                                {/* Market value and expiry time - Made prominent */}
                                <div className="mb-4">
                                    <button
                                        onClick={() => { setSelectedPlayer(player); setDataModalOpen(true); }}
                                        className="w-full bg-[#11a0f8] hover:bg-[#0d8ad9] text-white text-center py-1.5 px-3 rounded-lg transition-colors duration-300 text-lg mb-1 font-semibold"
                                    >
                                        {formatCurrency(player.marketValue)}
                                    </button>
                                    <div className="text-center font-semibold text-lg text-white mb-3">
                                        {formatTimeRemaining(player.expireInSeconds)}
                                    </div>
                                </div>

                                {/* Stats Grid - Compact version */}
                                <div className="grid grid-cols-2 gap-2 mb-3">
                                    <div className="bg-gray-700 p-1.5 rounded-lg text-center">
                                        <span className="block text-xs text-gray-400">Ø Punkte</span>
                                        <span className="text-base font-semibold text-white">{player.averagePoints || '0'}</span>
                                    </div>
                                    <div className="bg-gray-700 p-1.5 rounded-lg text-center">
                                        <span className="block text-xs text-gray-400">Gesamt</span>
                                        <span className="text-base font-semibold text-white">{player.totalPoints || '0'}</span>
                                    </div>
                                    <div className="bg-gray-700 p-1.5 rounded-lg text-center">
                                        <span className="block text-xs text-gray-400">Tore</span>
                                        <span className="text-base font-semibold text-white">{player.goals || '0'}</span>
                                    </div>
                                    <div className="bg-gray-700 p-1.5 rounded-lg text-center">
                                        <span className="block text-xs text-gray-400">Assists</span>
                                        <span className="text-base font-semibold text-white">{player.assists || '0'}</span>
                                    </div>
                                </div>

                                {/* Market Trend - Compact version */}
                                <div className="bg-gray-700 p-1.5 rounded-lg flex justify-center items-center mb-2">
                                    <span className={`text-base font-semibold flex items-center gap-1.5 
                                        ${player.fourHourTrend > 0 ? 'text-green-500' : 
                                          player.fourHourTrend < 0 ? 'text-red-500' : 
                                          'text-gray-400'}`}
                                    >
                                        {formatCurrency(player.fourHourTrend || 0)}
                                        {player.fourHourTrend > 0 ? <FontAwesomeIcon icon={faArrowUp} size="sm" /> : 
                                         player.fourHourTrend < 0 ? <FontAwesomeIcon icon={faArrowDown} size="sm" /> : null}
                                    </span>
                                </div>

                                {/* Performance History - Only show if enough data */}
                                {player.performanceHistory && player.performanceHistory.filter(p => p && typeof p.p !== 'undefined').length >= 2 && (
                                    <div className="bg-gray-700 p-1.5 rounded-lg mb-2">
                                        <span className="text-xs text-gray-400 block mb-1">Leistungsverlauf:</span>
                                        <MiniTrendGraph 
                                            data={player.performanceHistory
                                                .filter(p => p && typeof p.p !== 'undefined')
                                                .map(p => ({ m: p.p }))} 
                                        />
                                    </div>
                                )}

                                {/* Next Match */}
                                {player.upcomingMatches && player.upcomingMatches[0] && (
                                    <div className="bg-gray-700 p-2 rounded-lg flex justify-between items-center">
                                        <span className="text-sm text-gray-400">Nächstes Spiel:</span>
                                        <div className="flex items-center">
                                            {/* Determine if it's a home or away game */}
                                            {(() => {
                                                const match = player.upcomingMatches[0];
                                                const isHome = match.t1 === player.teamId;
                                                const opponentTeamId = isHome ? match.t2 : match.t1;
                                                
                                                return (
                                                    <>
                                                        <img
                                                            src={`https://kickbase.b-cdn.net/pool/teams/${opponentTeamId}.png`}
                                                            alt="Opponent"
                                                            className="w-6 h-6 rounded-full"
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src = `https://kickbase.b-cdn.net/pool/teamsdummies/${opponentTeamId}.png`;
                                                            }}
                                                            loading="eager"
                                                            style={{ minWidth: '24px', minHeight: '24px' }}
                                                        />
                                                        <span className="text-sm text-white ml-2">
                                                            {isHome ? 'H' : 'A'}
                                                        </span>
                                                    </>
                                                );
                                            })()}
                                        </div>
                                    </div>
                                )}

                                {/* Recommendation section */}
                                <div className="bg-gray-700  rounded-lg mt-4">
                                    <div className="flex justify-between items-center cursor-pointer mb-2" onClick={() => toggleRecommendationDetails(player.id)}>
                                        {player.status === 1 ? (
                                            <span className="text-orange-500 font-medium p-4">
                                                {player.statusText}
                                            </span>
                                        ) : null}
                                        <div className="flex items-center">
                                            {!player.status === 1 && (
                                                <>
                                                    <span className={`font-bold ${getFitColor(playerData.fitPercentage)} mr-2`}>
                                                        {isNaN(playerData.fitPercentage) ? '0' : Math.round(playerData.fitPercentage)}%
                                                    </span>
                                                    <FontAwesomeIcon
                                                        icon={showRecommendationDetails[player.id] ? faChevronUp : faChevronDown}
                                                        className="text-blue-500"
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    {/* Keep the recommendation details section, but only show if status is not 1 */}
                                    {!player.status === 1 && showRecommendationDetails[player.id] && (
                                        <div className="mt-4 space-y-2 text-sm">
                                            {/* Form */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Form:</span>
                                                <span>{playerData.form || 'Normale Form'}</span>
                                            </div>

                                            {/* Daily Value Increase */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Tägliche Wertsteigerung:</span>
                                                <span>{formatCurrency(player.marketValueIncrease)}</span>
                                            </div>

                                            {/* Future Trend */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Zukünftiger Trend:</span>
                                                <span>{playerData.futureValueIncrease ? formatCurrency(playerData.futureValueIncrease.estimatedDailyChange) : 'N/A'}</span>
                                            </div>

                                            {/* Position Comparison */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Vergleich auf der Pos.:</span>
                                                <span>{playerData.comparisonResult ? `${playerData.comparisonResult.difference.toFixed(1)} Punkte/Spiel` : 'Keine Daten'}</span>
                                            </div>

                                            {/* Financial Fit */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Finanzielle Passung:</span>
                                                <span>{!isNaN(playerData.financialPercentage) ? `${playerData.financialPercentage.toFixed(1)}% vom Budget` : 'Keine Daten'}</span>
                                            </div>

                                            {/* Freie Plätze */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Freie Plätze auf Pos.:</span>
                                                <span>
                                                    {playerData.positionCounts[player.position] > playerData.maxPlayers[player.position] 
                                                        ? '-2' 
                                                        : `${playerData.positionCounts[player.position] || 0} / ${playerData.maxPlayers[player.position] || 'N/A'}`}
                                                </span>
                                            </div>

                                            {/* Players from Team */}
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Spieler aus dem Team:</span>
                                                <span>{playerData.teamPlayersCount !== undefined ? `${playerData.teamPlayersCount} im Team` : 'Keine Daten'}</span>
                                            </div>

                                            {/* Estimated Sale Price */}
                                            {/* <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Geschätzter Verkaufspreis:</span>
                                                <span>{playerData.estimatedSalePrice === '-' ? '-' : `${formatCurrency(playerData.estimatedSalePrice)} `}</span>
                                            </div>

                                           
                                            <div className="bg-gray-600 p-2 rounded-lg flex justify-between items-center">
                                                <span>Spiele absolviert:</span>
                                                <span>{`${playerData.gamesPlayed} / ${playerData.totalGames}`}</span>
                                            </div> */}
                                            }
                                        </div>
                                    )}
                                </div>

                                {/* Add VoteButtons component with info icon */}
                                <div className="mt-4">
                                    <div className="bg-gray-700 p-2 rounded-lg">
                                        <div className="flex items-center justify-between">
                                            <span className="text-sm font-bold text-white flex items-center">
                                                Deine Einschätzung
                                                <FontAwesomeIcon 
                                                    icon={faInfoCircle} 
                                                    className="ml-2 text-blue-400 cursor-pointer"
                                                    data-tooltip-id="vote-info"
                                                    data-tooltip-content="Gib deine Meinung ab, ob du diesen Spieler zu diesem Zeitpunkt kaufen oder empfehlen würdest. Die Bewertungen werden alle vier Wochen zurückgesetzt."
                                                />
                                            </span>
                                            <VoteButtons 
                                                playerId={player.id} 
                                                userId={userId} 
                                            />
                                        </div>
                                    </div>
                                </div>

                                <button
                                    onClick={() => handleCompareClick(player)}
                                    className="w-full mt-4 bg-[#11a0f8] hover:bg-[#0d8ad9] text-white py-2 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-2 font-semibold"
                                >
                                    <FontAwesomeIcon icon={faChartBar} className="text-white" />
                                    <span>Vergleichen</span>
                                </button>
                            </div>
                        );
                    })}
                </div>
            )}

            {/* Modals for Player Detail and Search */}
            {dataModalOpen && selectedPlayer && (
                <PlayerDetailModal 
                    player={selectedPlayer} 
                    onClose={() => setDataModalOpen(false)}
                />
            )}
            {searchModalOpen && (
                <Modal
                    isOpen={searchModalOpen}
                    onRequestClose={() => setSearchModalOpen(false)}
                    overlayClassName="fixed inset-0 flex items-center justify-center z-50"
                    className="bg-transparent"
                >
                    <button
                        className="absolute top-2 right-2 text-4xl text-gray-600 hover:text-gray-800 transition duration-150 ease-in-out"
                        onClick={() => setSearchModalOpen(false)}
                    >
                        &times;
                    </button>
                    <PlayerSearch onClose={() => setSearchModalOpen(false)} />
                </Modal>
            )}

            {showNotificationDialog && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm">
                        <h2 className="text-xl font-bold mb-4">Benachrichtigungen aktivieren</h2>
                        <p className="mb-4">
                            Wir möchten dir Benachrichtigungen senden, um dich 10 Minuten vor dem Auktionsende eines Spielers zu informieren. 
                            Dies hilft dir, keine wichtigen Auktionen zu verpassen.
                        </p>
                        <div className="flex justify-end space-x-4">
                            <button 
                                onClick={() => setShowNotificationDialog(false)} 
                                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
                            >
                                Abbrechen
                            </button>
                            <button 
                                onClick={handleNotificationDialogConfirm} 
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                                Erlauben
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Add Tooltip component outside of the map function */}
            <Tooltip id="vote-info" place="top" effect="solid" className="max-w-xs text-sm" />

            {/* Add a button in your component to trigger this function */}

            {showComparison && selectedPlayerForComparison && (
                <PlayerComparisonOverlay
                    currentPlayer={selectedPlayerForComparison}
                    onClose={() => setShowComparison(false)}
                />
            )}

        </div >
    );
}
);

export default MarketOverview;