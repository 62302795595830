import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { usePlayerStore } from './stores/PlayerStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

const LeagueSelector = observer(({ onLeagueChange, selectedLeagueId }) => {
    const playerStore = usePlayerStore();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    // Single initialization effect
    useEffect(() => {
        const initializeLeague = async () => {
            if (isLoading || !playerStore.leagues?.length) return;

            try {
                setIsLoading(true);
                setError(null);

                const storedLeagueId = localStorage.getItem('selectedLeagueId');
                const validLeagueId = storedLeagueId && 
                    playerStore.leagues.some(l => l.id === storedLeagueId)
                    ? storedLeagueId
                    : playerStore.leagues[0].id;

                if (validLeagueId !== selectedLeagueId) {
                    await playerStore.setLeagueId(validLeagueId);
                    onLeagueChange(validLeagueId);
                }
            } catch (error) {
                console.error('Error initializing league:', error);
                setError('Fehler beim Laden der Liga');
            } finally {
                setIsLoading(false);
            }
        };

        initializeLeague();
    }, [playerStore.leagues]); // Only depend on leagues array

    const handleLeagueChange = async (leagueId) => {
        if (isLoading || leagueId === selectedLeagueId) return;

        try {
            setIsLoading(true);
            await playerStore.setLeagueId(leagueId);
            onLeagueChange(leagueId);
            localStorage.setItem('selectedLeagueId', leagueId);
        } catch (error) {
            console.error('Error changing league:', error);
            toast.error('Fehler beim Wechseln der Liga');
        } finally {
            setIsLoading(false);
        }
    };

    if (!playerStore.leagues?.length) {
        return (
            <div className="bg-gray-800 text-white p-4 rounded shadow-md">
                <div className="text-sm text-gray-400">
                    Keine Ligen verfügbar
                </div>
            </div>
        );
    }

    return (
        <div className="bg-gray-800 text-white p-4 rounded shadow-md">
            <select
                value={selectedLeagueId || ''}
                onChange={(e) => handleLeagueChange(e.target.value)}
                className="w-full p-2 border bg-gray-700 text-white rounded focus:outline-none focus:ring-1 focus:ring-indigo-500"
                disabled={isLoading}
            >
                {playerStore.leagues.map((league) => (
                    <option key={league.id} value={league.id}>
                        {league.name} ({league.budget.toLocaleString()} €)
                    </option>
                ))}
            </select>
            {isLoading && (
                <div className="mt-2 text-sm text-gray-400 flex items-center">
                    <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                    Lade...
                </div>
            )}
            {error && (
                <div className="mt-2 text-sm text-red-400">
                    {error}
                </div>
            )}
        </div>
    );
});

export default LeagueSelector;

