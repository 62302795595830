import React, { useState } from 'react';
import { playerService } from '../api/playerService';
import { authService } from '../api/authService';
import { observer } from 'mobx-react-lite';

const PlayerDebugView = observer(() => {
    const [leagueId, setLeagueId] = useState('');
    const [playerId, setPlayerId] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(authService.isAuthenticated());
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [playerData, setPlayerData] = useState(null);
    const [leagues, setLeagues] = useState(authService.getLeagues());
    const [isMinimized, setIsMinimized] = useState(false);

    const handleLogin = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await authService.login(email, password);
            setIsLoggedIn(true);
            setLeagues(response.leagues);
            setEmail('');
            setPassword('');
        } catch (err) {
            setError(err.message || 'Login failed');
        } finally {
            setLoading(false);
        }
    };

    const handleLogout = () => {
        authService.logout();
        setIsLoggedIn(false);
        setPlayerData(null);
    };

    const fetchPlayerData = async () => {
        setLoading(true);
        setError(null);
        try {
            const data = await playerService.getEnhancedPlayerData(leagueId, playerId);
            setPlayerData(data);
            console.log('Enhanced player data:', data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    // Only render in development
    if (process.env.NODE_ENV === 'production') return null;

    return (
        <div className="fixed bottom-0 right-0 p-4 bg-white border shadow-lg w-[600px] max-h-[80vh] overflow-auto">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-bold">Player Data Debug</h2>
                <button 
                    onClick={() => setIsMinimized(!isMinimized)}
                    className="text-gray-500 hover:text-gray-700"
                >
                    {isMinimized ? '🔽' : '🔼'}
                </button>
            </div>

            {!isMinimized && (
                <>
                    {!isLoggedIn ? (
                        <div className="space-y-2 mb-4">
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="border p-2 w-full"
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="border p-2 w-full"
                            />
                            <button
                                onClick={handleLogin}
                                disabled={loading}
                                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 w-full"
                            >
                                {loading ? 'Logging in...' : 'Login'}
                            </button>
                        </div>
                    ) : (
                        <>
                            <button
                                onClick={handleLogout}
                                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 mb-4"
                            >
                                Logout
                            </button>
                            
                            <div className="flex gap-2 mb-4">
                                <input 
                                    type="text"
                                    placeholder="League ID"
                                    value={leagueId}
                                    onChange={(e) => setLeagueId(e.target.value)}
                                    className="border p-2 flex-1"
                                />
                                <input 
                                    type="text"
                                    placeholder="Player ID"
                                    value={playerId}
                                    onChange={(e) => setPlayerId(e.target.value)}
                                    className="border p-2 flex-1"
                                />
                                <button
                                    onClick={fetchPlayerData}
                                    disabled={loading}
                                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-gray-400"
                                >
                                    {loading ? 'Loading...' : 'Fetch Data'}
                                </button>
                            </div>

                            {error && (
                                <div className="text-red-500 mb-4">
                                    Error: {error}
                                </div>
                            )}

                            {playerData && (
                                <div className="space-y-4">
                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <h3 className="font-bold">Basic Info</h3>
                                            <p>Name: {playerData.fullName}</p>
                                            <p>Team: {playerData.teamName}</p>
                                            <p>Position: {playerData.position}</p>
                                            <p>Status: {playerData.statusText}</p>
                                        </div>
                                        <div>
                                            <h3 className="font-bold">Performance</h3>
                                            <p>Total Points: {playerData.totalPoints}</p>
                                            <p>Average Points: {playerData.averagePoints}</p>
                                            <p>Goals: {playerData.goals}</p>
                                            <p>Assists: {playerData.assists}</p>
                                        </div>
                                    </div>
                                    
                                    <div>
                                        <h3 className="font-bold">Market Data</h3>
                                        <p>Current Value: {playerData.marketValue?.toLocaleString()} €</p>
                                        <p>Trend: {playerData.marketValueTrend}</p>
                                        {playerData.marketStats && (
                                            <>
                                                <p>7 Day Trend: {playerData.marketStats.sevenDayTrend?.toLocaleString()} €</p>
                                                <p>4 Hour Trend: {playerData.marketStats.fourHourTrend?.toLocaleString()} €</p>
                                            </>
                                        )}
                                    </div>

                                    <div>
                                        <h3 className="font-bold">Raw Data</h3>
                                        <pre className="bg-gray-100 p-2 text-xs overflow-auto max-h-60">
                                            {JSON.stringify(playerData, null, 2)}
                                        </pre>
                                    </div>
                                </div>
                            )}

                            {isLoggedIn && (
                                <div className="mb-4">
                                    <h3 className="font-bold mb-2">Your Leagues</h3>
                                    <select 
                                        value={leagueId}
                                        onChange={(e) => setLeagueId(e.target.value)}
                                        className="w-full p-2 border rounded"
                                    >
                                        <option value="">Select a League</option>
                                        {leagues.map(league => (
                                            <option key={league.id} value={league.id}>
                                                {league.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
});

export default PlayerDebugView; 