import React, { createContext, useState, useContext, useCallback } from 'react';
import { doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { firestore } from '../firebase';

const VoteContext = createContext();

export const VoteProvider = ({ children }) => {
  const [votes, setVotes] = useState({});
  const [userVotes, setUserVotes] = useState({});
  const [isLoading, setIsLoading] = useState({});

  const fetchVotes = useCallback(async (playerId) => {
    if (!playerId) {
      console.log('fetchVotes: No playerId provided');
      return;
    }

    console.log('Fetching votes for playerId:', playerId);

    try {
      const voteRef = doc(firestore, 'playerVotes', playerId);
      const voteDoc = await getDoc(voteRef);
      
      if (voteDoc.exists()) {
        const voteData = voteDoc.data();
        console.log('Fetched vote data:', voteData);
        setVotes(prev => ({
          ...prev,
          [playerId]: {
            upvotes: voteData.upvotes || 0,
            downvotes: voteData.downvotes || 0
          }
        }));
      } else {
        console.log('No existing votes, creating initial document');
        // Create initial document if it doesn't exist
        await setDoc(voteRef, {
          upvotes: 0,
          downvotes: 0
        });
        setVotes(prev => ({
          ...prev,
          [playerId]: { upvotes: 0, downvotes: 0 }
        }));
      }
    } catch (error) {
      console.error('Error in fetchVotes:', error);
    }
  }, []);

  const fetchUserVote = useCallback(async (playerId, userId) => {
    if (!playerId || !userId) return;

    try {
      const userVoteRef = doc(firestore, 'userVotes', `${userId}_${playerId}`);
      const userVoteDoc = await getDoc(userVoteRef);
      
      if (userVoteDoc.exists()) {
        setUserVotes(prev => ({
          ...prev,
          [playerId]: userVoteDoc.data().vote
        }));
      }
    } catch (error) {
      console.error('Error fetching user vote:', error);
    }
  }, []);

  const handleVote = async (playerId, userId, voteType) => {
    console.log('handleVote called with:', { playerId, userId, voteType });
    if (!userId || !playerId || isLoading[playerId]) {
        console.log('Vote blocked:', { 
            noUserId: !userId, 
            noPlayerId: !playerId, 
            isLoading: isLoading[playerId] 
        });
        return;
    }
    
    setIsLoading(prev => ({ ...prev, [playerId]: true }));
    
    try {
      const voteRef = doc(firestore, 'playerVotes', playerId);
      const userVoteRef = doc(firestore, 'userVotes', `${userId}_${playerId}`);
      const currentUserVote = userVotes[playerId];
      
      // If clicking the same vote type, remove the vote
      if (currentUserVote === voteType) {
        await updateDoc(voteRef, {
          [`${voteType}s`]: increment(-1)
        });
        
        await setDoc(userVoteRef, { 
          vote: null,
          timestamp: new Date()
        });
        
        setUserVotes(prev => ({
          ...prev,
          [playerId]: null
        }));
        
        setVotes(prev => ({
          ...prev,
          [playerId]: {
            ...prev[playerId],
            [`${voteType}s`]: Math.max(0, prev[playerId][`${voteType}s`] - 1)
          }
        }));
      } else {
        const updates = {};
        
        if (currentUserVote) {
          updates[`${currentUserVote}s`] = increment(-1);
        }
        updates[`${voteType}s`] = increment(1);
        
        await updateDoc(voteRef, updates);
        
        await setDoc(userVoteRef, {
          vote: voteType,
          timestamp: new Date()
        });
        
        setUserVotes(prev => ({
          ...prev,
          [playerId]: voteType
        }));
        
        setVotes(prev => ({
          ...prev,
          [playerId]: {
            ...prev[playerId],
            [`${currentUserVote}s`]: currentUserVote ? Math.max(0, prev[playerId][`${currentUserVote}s`] - 1) : prev[playerId][`${currentUserVote}s`],
            [`${voteType}s`]: prev[playerId][`${voteType}s`] + 1
          }
        }));
      }
    } catch (error) {
      console.error('Error updating vote:', error);
    } finally {
      setIsLoading(prev => ({ ...prev, [playerId]: false }));
    }
  };

  return (
    <VoteContext.Provider value={{ 
      votes,
      userVotes,
      isLoading,
      fetchVotes,
      fetchUserVote,
      handleVote
    }}>
      {children}
    </VoteContext.Provider>
  );
};

export const useVotes = () => useContext(VoteContext);
